import React, { useState } from 'react';

import { ChevronDown } from 'lucide-react';

import PropTypes from 'prop-types';

import PipelineItemBody from './pipeline-item-body';
import PipelineItemHeader from './pipeline-item-header';
import PipelineItemTitle from './pipeline-item-title';
import PipelineTag from './pipeline-tag';

const PipelineItem = ({ itemProps, column, item }) => {
    const tags = itemProps.tags(item);
    const [open, setOpen] = useState(false);

    return (
        <div className="flex flex-col gap-4 rounded-3xl bg-layout-transparent px-6 pb-4 pt-6 shadow">
            <div className="flex flex-col gap-6">
                {tags.length > 0 && (
                    <div className="flex h-12 items-center justify-end gap-2">
                        {tags.map((tag, index) => {
                            return <PipelineTag key={index} tag={tag} />;
                        })}
                    </div>
                )}
                <PipelineItemTitle itemProps={itemProps} item={item} column={column} />
                <PipelineItemHeader itemProps={itemProps} item={item} />
                <PipelineItemBody itemProps={itemProps} item={item} open={open} />
            </div>
            <div
                className={`mx-auto flex h-16 w-16 transform cursor-pointer items-center justify-center rounded-full border border-layout-transparent bg-layout-transparent transition-transform duration-300 hover:bg-layout-transparent-dark ${
                    open ? 'rotate-180' : 'rotate-0'
                }`}
                onClick={() => setOpen((prev) => !prev)}
            >
                <ChevronDown />
            </div>
        </div>
    );
};

PipelineItem.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    column: PropTypes.object,
};

PipelineItem.defaultProps = {
    itemProps: {},
    item: {},
    column: {
        key: 'DEFAULT',
        title: 'Placeholder',
        color: 'var(--main-text)',
        backgroundColor: 'var(--primary-main)',
        borderColor: 'var(--layout-transparent)',
    },
};

export default PipelineItem;
