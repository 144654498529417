import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Clipboard,
    ClipboardCheck,
    ClipboardList,
    ClipboardPenLine,
    FileText,
    Plus,
    SquareArrowOutUpRight,
    User,
} from 'lucide-react';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { ReactComponent as ClientIcon } from 'assets/pipelines/svgs/client-icon.svg';
import { ReactComponent as HighestIcon } from 'assets/pipelines/svgs/highest-icon.svg';
import { ReactComponent as LowestIcon } from 'assets/pipelines/svgs/lowest-icon.svg';
import { ReactComponent as RecentIcon } from 'assets/pipelines/svgs/recent-icon.svg';
import { ReactComponent as SpecificIcon } from 'assets/pipelines/svgs/specific-icon.svg';

import { Button } from '@material-ui/core';

import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PageFilter from 'components/shared/page-filter/page-filter';
import PipelineContent from 'components/shared/pipelines/pipeline-content';
import Sort from 'components/shared/sort/sort';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { projectInManagement, projectManagement } from 'routes';
import { errorHandling, formatDate } from 'utils';
import API from 'utils/axios';

const DEFAULT_PIPELINE_STATE = {
    length: 0,
    content: {
        NEW: {
            length: 0,
            content: {
                REST: [],
            },
        },
        IN_PROGRESS: {
            length: 0,
            content: {
                REST: [],
            },
        },
        REVIEW: {
            length: 0,
            content: {
                REST: [],
            },
        },
        DONE: {
            length: 0,
            content: {
                REST: [],
            },
        },
    },
};

const ProjectsPipeline = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [loading, setLoading] = useState(true);

    const [clients, setClients] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [projects, setProjects] = useState([]);
    const [formattedProjects, setFormattedProjects] = useState(DEFAULT_PIPELINE_STATE);

    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '19',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const columns = [
        {
            title: t('New'),
            key: 'NEW',
            color: 'var(--main-text)',
            backgroundColor: '#FB924B',
            borderColor: 'var(--layout-transparent)',
        },
        {
            title: t('In progress'),
            key: 'IN_PROGRESS',
            color: 'var(--main-text)',
            backgroundColor: '#1D8CF8',
            borderColor: 'var(--layout-transparent)',
        },
        {
            title: t('Review'),
            key: 'REVIEW',
            color: 'var(--main-text)',
            backgroundColor: '#3587A4',
            borderColor: 'var(--layout-transparent)',
        },
        {
            title: t('Done'),
            key: 'DONE',
            color: 'var(--main-text)',
            backgroundColor: '#3EC356',
            borderColor: 'var(--layout-transparent)',
        },
    ];

    const sortKeys = [
        {
            key: 'REST',
            title: t('All projects'),
            chipStyles: {
                color: 'var(--main-text)',
                borderColor: 'var(--disabled)',
                fontSize: '1rem',
            },
            lines: [
                {
                    styles: {
                        backgroundColor: 'var(--main-text)',
                    },
                },
            ],
            render: true,
        },
    ];

    const [sort, setSort] = useState([
        {
            key: 'createAt',
            label: t('Creation date'),
            value: 'desc',
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
        {
            key: 'updateAt',
            label: t('Last update'),
            value: null,
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
    ]);

    const [filter, setFilter] = useState([]);
    const filterOptions = [
        {
            id: 'client-group',
            icon: <ClientIcon />,
            label: 'Client',
            data: null,
            list: [
                {
                    id: 'contactId',
                    icon: <RecentIcon />,
                    label: 'Recent',
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: 'Client',
                            render: (value) => clients.find((client) => client.id === value).data.standard.name,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'list',
                                options: clients.slice(0, 4),
                                label: t('Select client'),
                                render: (item) => ({ icon: <ClientIcon />, label: item.data.standard.name }),
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'contactId',
                    icon: <SpecificIcon />,
                    label: t('Specific client'),
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: 'Client',
                            render: (value) => clients.find((client) => client.id === value).data.standard.name,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'select',
                                options: clients,
                                label: t('Select client'),
                                render: (item) => item.data.standard.name,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'contractId',
            icon: <FileText />,
            label: t('Contract'),
            data: {
                defaultValue: null,
                filterData: {
                    label: t('Contract'),
                    render: (value) => contracts.find((contract) => contract.id === value).data.standard.number,
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'select',
                        options: contracts,
                        label: t('Select contract'),
                        render: (item) => item.data.standard.number,
                    },
                ],
            },
            list: null,
        },
    ];

    const mappedStatusData = {
        NEW: {
            label: t('New'),
            icon: <Clipboard />,
        },
        IN_PROGRESS: {
            label: t('In progress'),
            icon: <ClipboardPenLine />,
        },
        REVIEW: {
            label: t('Review'),
            icon: <ClipboardList />,
        },
        DONE: {
            label: t('Done'),
            icon: <ClipboardCheck />,
        },
    };

    const itemProps = {
        tags: () => [],
        title: {
            icon: <User />,
            render: (item) => item.contact.data.standard.name,
            status: (item) => mappedStatusData[item.statusHistory.at(-1).status],
        },
        header: {
            render: (item) => item.name,
        },
        body: {
            renderVisibleRows: (item) => [
                {
                    label: t('Contract'),
                    content: item.Contract ? item.Contract.data.standard.number : '-',
                    canRender: Boolean(item.contractId),
                },
                {
                    label: t('Last update'),
                    content: formatDate(item.updateAt),
                    canRender: true,
                },
            ],
            renderExpandedRows: (item) => [
                {
                    label: t('Creation date'),
                    content: formatDate(item.createAt),
                    canRender: true,
                },
                {
                    label: t('No. of tasks'),
                    content: item.Activity.filter((activity) => activity.actionType === 'TASK').length,
                    canRender: true,
                },
                {
                    label: t('No. of completed tasks'),
                    content: item.Activity.filter(
                        (activity) => activity.actionType === 'TASK' && activity.status === 'DONE',
                    ).length,
                    canRender: true,
                },
                {
                    label: t('No. of contracts'),
                    content: item.contractsPM.length,
                    canRender: true,
                },
                {
                    label: t('No. of invoices'),
                    content: item.Invoice.length,
                    canRender: true,
                },
            ],
        },
        button: {
            render: () => t('Open project'),
            icon: <SquareArrowOutUpRight />,
            disabled: false,
            action: (item) =>
                history.push(
                    projectInManagement.base + '/' + item.id + projectInManagement.planingOverviewOnProject.base,
                ),
            canRender: () => true,
        },
    };

    const getClients = async () => {
        try {
            const response = await API.get('/contacts', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    type: 'ALL',
                },
            });
            const fetchedClients = response.data.data.contacts.map((client) => ({
                ...client,
                data: typeof client.data === 'string' ? JSON.parse(client.data) : client.data,
            }));

            setClients(fetchedClients);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getContracts = async () => {
        try {
            const response = await API.get('/contracts', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });
            const fetchedContracts = response.data.contracts.map((contract) => ({
                ...contract,
                data: typeof contract.data === 'string' ? JSON.parse(contract.data) : contract.data,
            }));

            setContracts(fetchedContracts);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleFormatProjects = (projects) => {
        return projects
            .map((project) => ({
                ...project,
                contact:
                    typeof project.contact.data === 'string'
                        ? { ...project.contact, data: JSON.parse(project.contact.data) }
                        : project.contact,
                Contract:
                    project.Contract && typeof project.Contract.data === 'string'
                        ? { ...project.Contract, data: JSON.parse(project.Contract.data) }
                        : project.Contract,
            }))
            .reduce(
                (acc, curr) => ({
                    ...acc,
                    length: projects.length,
                    content: {
                        ...acc.content,
                        [curr.statusHistory.at(-1).status]: {
                            ...acc.content[curr.statusHistory.at(-1).status],
                            length: acc.content[curr.statusHistory.at(-1).status].length + 1,
                            content: {
                                ...acc.content[curr.statusHistory.at(-1).status].content,
                                ['REST']: [...acc.content[curr.statusHistory.at(-1).status].content['REST'], curr],
                            },
                        },
                    },
                }),
                DEFAULT_PIPELINE_STATE,
            );
    };

    const getProjects = async (sort, filter) => {
        try {
            const querySort = sort
                .filter((element) => element.value !== null)
                .reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});

            const queryFilter = filter.reduce(
                (acc, curr) => ({ ...acc, [curr.metadata.backendKey ?? curr.key]: curr.value }),
                {},
            );

            const response = await API.get('pm_projects_pipeline', {
                params: { ...querySort, ...queryFilter },
            });
            const formattedProjects = handleFormatProjects(response.data.projects, sort);
            setFormattedProjects(formattedProjects);
            setProjects(response.data.projects);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    useEffect(() => {
        if (!canView) history.push('/');

        (async () => {
            try {
                await Promise.all([getProjects(sort, filter), getClients(), getContracts()]);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t(errorHandling(error)), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, [canView]);

    const handleSort = async (sort, lastAppliedSort) => {
        try {
            setLoading(true);
            await Promise.all([getProjects(sort, filter), getClients(), getContracts()]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilter = async (filter) => {
        try {
            setLoading(true);
            await Promise.all([getProjects(sort, filter), getClients(), getContracts()]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Projects pipeline')}</title>
            </Helmet>

            <Header
                pageTitle={t('Projects pipeline')}
                action={
                    <div className="ml-auto flex gap-6">
                        <Sort
                            disabled={loading || formattedProjects.length === 0}
                            sort={sort}
                            setSort={setSort}
                            onSort={handleSort}
                        />

                        {canAll && (
                            <Button
                                color="secondary"
                                startIcon={<Plus />}
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push(
                                        projectManagement.base +
                                            projectManagement.projectEntity.base +
                                            projectManagement.projectEntity.create,
                                    )
                                }
                            >
                                {t('Add new project')}
                            </Button>
                        )}
                    </div>
                }
            />

            <div className="page-container">
                <div className="flex flex-col gap-6 xl:gap-12">
                    <PageFilter
                        disabled={loading}
                        title={t('Filter projects')}
                        filter={filter}
                        setFilter={setFilter}
                        filterOptions={filterOptions}
                        onFilter={handleFilter}
                        mobileBP="xl"
                    />
                    {loading ? (
                        <Loading style={{ height: '70vh' }} />
                    ) : formattedProjects.length ? (
                        <PipelineContent
                            columns={columns}
                            sortKeys={sortKeys}
                            itemProps={itemProps}
                            data={formattedProjects}
                            mobileBP="xl"
                        />
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </div>
            </div>
        </>
    );
};

export default ProjectsPipeline;
