import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LinkIcon from '@material-ui/icons/Link';

import { Button, ClickAwayListener } from '@material-ui/core';

import AddComment from 'components/internal-activity/add-comment';
import BasicTooltip from 'components/shared/basic-tooltip';
import ActivityContext from 'contexts/ActivityContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { configurator, crm } from 'routes';
import { daysFrom, formatDate, formatTime, linkStyle } from 'utils';
import API from 'utils/axios';

import SubilestoneDisplay from './submilestone-display';

export const ActionArrow = (props) => {
    const { actions } = props;
    const [open, setOpen] = useState(false);
    const filteredActions = actions.filter((action) => !('render' in action) || action.render);

    return (
        <>
            {filteredActions.length > 0 ? (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div className="relative">
                        <div
                            className="relative flex h-8 w-8 cursor-pointer items-center justify-center rounded-full hover:bg-layout-transparent"
                            onClick={() => setOpen(!open)}
                        >
                            <KeyboardArrowDownIcon
                                className={`transform text-main-text transition-all duration-200 ${
                                    open ? 'rotate-180' : 'rotate-0'
                                }`}
                                style={{
                                    fontSize: '1.5rem',
                                    transition: 'transform .15s ease',
                                }}
                            />
                        </div>

                        <ul
                            className={`no-user-select-recursive absolute left-0 top-full z-50 transform rounded-md bg-primary-main py-2 transition-all duration-150 md:left-auto md:right-0 ${
                                open
                                    ? 'pointer-events-auto translate-y-0 opacity-100'
                                    : 'pointer-events-none translate-y-4 opacity-0'
                            }`}
                        >
                            {filteredActions.map((action) => (
                                <li
                                    key={action.name}
                                    onClick={() => {
                                        action.action();
                                        setOpen(false);
                                    }}
                                    className="w-full cursor-pointer py-1 pl-5 pr-16 text-buttons-text transition-colors hover:bg-primary-light xl:px-5"
                                >
                                    {action.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                </ClickAwayListener>
            ) : null}
        </>
    );
};

ActionArrow.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            action: PropTypes.func,
        }),
    ),
};

ActionArrow.defaultProps = {
    actions: [],
};

const Comment = (props) => {
    const { comment, userId } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const { fetchActivities } = useContext(ActivityContext);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const deleteComment = async () => {
        if (isDeleting) return;

        setIsDeleting(true);
        try {
            await API.delete('activities_comments', {
                data: {
                    id: comment.id,
                },
            });
            enqueueSnackbar(comment?.comment ? t('Comment deleted!') : t('Document/s deleted!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
        } finally {
            fetchActivities();
        }
    };

    const fileNameFromKey = useCallback((key) => {
        const spl = key.split('/');
        const uuidPart = spl[spl.length - 1];
        const fileName = uuidPart.split('-').slice(5).join('');
        return fileName;
    }, []);

    return (
        <>
            {comment !== null && (
                <div
                    className={`relative mb-1.5 flex flex-col gap-2 rounded-br-md rounded-tr-md border-l border-secondary-light bg-layout-transparent px-8 py-3 transition-colors hover:bg-layout-transparent-dark ${
                        isDeleting ? 'hidden' : 'block'
                    }`}
                >
                    {comment.authorId === userId && (
                        <div className="absolute right-2 top-2">
                            <ActionArrow
                                actions={
                                    comment.authorId === userId
                                        ? [
                                              {
                                                  name: t('Delete'),
                                                  action: deleteComment,
                                              },
                                          ]
                                        : []
                                }
                            />
                        </div>
                    )}

                    {comment?.comment !== null && comment?.comment !== '' && (
                        <p className="mb-2 text-main-text">{comment.comment}</p>
                    )}
                    <p className="text-dark-text">
                        <span className="font-semibold">{comment.author?.profile?.name}</span>{' '}
                        {`${formatDate(comment.createAt)} ${formatTime(comment.createAt)}`}
                    </p>

                    {/* Comment Files */}
                    {comment.files?.length > 0 && (
                        <div className="relative z-20 flex flex-col gap-1">
                            {comment.files.map((file) => (
                                <div
                                    key={file.id}
                                    className="flex items-center justify-between border-b border-layout-lighter py-1"
                                >
                                    <a
                                        className="flex items-center"
                                        target="_blank"
                                        href={file.file.url}
                                        download={fileNameFromKey(file.file.key)}
                                        rel="noreferrer"
                                    >
                                        <div className="mr-1 flex h-6 w-6 items-center justify-center">
                                            <InsertDriveFileIcon
                                                className="text-secondary-lighter"
                                                style={{
                                                    fontSize: '1.5rem',
                                                }}
                                            />
                                        </div>
                                        <p className="text-medium mr-2 cursor-pointer py-1 opacity-75 transition-opacity hover:opacity-100">
                                            {fileNameFromKey(file.file.key)}
                                        </p>
                                    </a>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

Comment.propTypes = {
    comment: PropTypes.object,
    userId: PropTypes.string,
};

Comment.defaultProps = {
    comment: null,
    userId: null,
};

const Activity = (props) => {
    const {
        onDelete,
        activity,
        deleteActivity,
        updateActivity,
        actionArrow,
        deleteIcon,
        commentView,
        milestoneCheck,
        check,
        userLink,
        setSeeGantt,
        setEditActivity,
        editDocuments,
        onNotify,
    } = props;

    const { currencyObj, theme } = useContext(GlobalContext);
    const confirm = useConfirm();

    const [checked, setChecked] = useState(false);

    const history = useHistory();
    const { user } = useContext(UserContext);
    const globalContext = useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();
    const { isContact, fetchActivities } = useContext(ActivityContext);
    const { t } = useTranslation();

    useEffect(() => {
        setChecked(activity.status === 'DONE');
    }, [activity.status]);

    const activityBorderColors = {
        COMMENT: 'border-blue-500',
        TASK: 'border-green-500',
        FILES: 'border-purple-500',
        DOCUMENTS: 'border-yellow-500',
        INVOICES_AND_BILLS: 'border-red-500',
    };

    const activityBackgroundColors = {
        COMMENT: 'bg-blue-500',
        TASK: 'bg-green-500',
        FILES: 'bg-purple-500',
        DOCUMENTS: 'bg-yellow-500',
        INVOICES_AND_BILLS: 'bg-red-500',
    };

    const activityTextColors = {
        COMMENT: 'text-blue-500',
        TASK: 'text-green-500',
        FILES: 'text-purple-500',
        DOCUMENTS: 'text-yellow-500',
        INVOICES_AND_BILLS: 'text-red-500',
    };

    // Add comment function
    const submitHandle = async (activityId, newComment, newCommentFiles) => {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                activityId,
                comment: newComment.trim(),
            }),
        );

        if (newCommentFiles.length) {
            newCommentFiles.forEach((file) => formData.append('files', file.blob));
        }

        try {
            await API.post('/activities_comments', formData);
            enqueueSnackbar(t('Comment added successfully!'), { variant: 'success' });
        } catch (err) {
            console.error(err);
        } finally {
            fetchActivities();
        }
    };

    // const invoiceTypeDisplayed = (invoice) => {
    //     let typeDisplay;
    //     switch (invoice?.type) {
    //         case 'EXPENSE':
    //             typeDisplay = t('Expense');
    //             break;
    //         case 'ADVANCE':
    //             typeDisplay = t('Advance');
    //             break;
    //         case 'REVERSAL':
    //             typeDisplay = t('Reversal');
    //             break;
    //         case 'INCOME':
    //             if (invoice.incomeType === 'SIMPLE') {
    //                 typeDisplay = t('Income');
    //             } else if (invoice.incomeType === 'REVERSE') {
    //                 typeDisplay = t('Income with advance reversal');
    //             }
    //             break;
    //     }

    //     return typeDisplay?.toUpperCase();
    // };

    return (
        <>
            {activity !== null && user?.id && (
                <div className="relative mb-12 last:mb-0">
                    {/* Activity checkbox and date */}
                    <div
                        className={`absolute -left-8 top-5 h-6 w-6 rounded-full border-2 sm:-left-4 ${
                            activityBorderColors[activity.actionType]
                        } cursor-pointer transition-colors ${
                            checked ? activityBackgroundColors[activity.actionType] : 'bg-layout-main'
                        }`}
                        style={{ transform: 'translateX(calc(-50% - 1px))' }}
                        onClick={() => {
                            if (user.id !== activity.authorId) {
                                enqueueSnackbar(
                                    t('You must be the author of this task in order to mark it as completed!'),
                                    { variant: 'error' },
                                );
                                return;
                            }
                            confirm(t('Are you sure you want to set as done this activity?'), () => {
                                if (!check) return;
                                setChecked(!checked);
                                setTimeout(() => {
                                    updateActivity(activity, activity.status === 'TODO' ? 'DONE' : 'TODO');
                                }, 250);
                            });
                        }}
                    >
                        <CheckIcon
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '1rem',
                            }}
                            className={`${checked ? 'opacity-100' : 'opacity-0'} text-buttons-text  transition-opacity`}
                        />

                        <div className="absolute top-1/2 -translate-x-full -translate-y-1/2 transform p-2.5 pr-5 text-right sm:flex sm:translate-x-10 sm:translate-y-2 sm:gap-6">
                            <p
                                style={{ userSelect: 'none' }}
                                className={`whitespace-nowrap text-right font-semibold ${
                                    activityTextColors[activity.actionType]
                                }`}
                            >{`${
                                daysFrom(activity.createAt) !== 0 ? Math.abs(daysFrom(activity.createAt)) : t('today')
                            } ${
                                daysFrom(activity.createAt) > 0
                                    ? t('days ago')
                                    : daysFrom(activity.createAt) === 1
                                      ? t('Yesterday')
                                      : ''
                            }`}</p>
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right font-semibold">
                                {formatDate(activity.createAt, false)}
                            </p>
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right text-dark-text">
                                {formatTime(activity.createAt)}
                            </p>
                        </div>
                    </div>

                    {/* Activity department */}
                    <div className="absolute left-6 top-0 -translate-y-1/2 transform">
                        <div className="flex gap-4">
                            <p
                                className={`border-2 ${
                                    activityBorderColors[activity.actionType]
                                } cursor-pointer rounded-full px-5 py-3 font-bold`}
                                style={{
                                    backdropFilter: 'blur(6px)',
                                }}
                            >
                                {activity.departament.name}
                            </p>
                            {/* {activity.actionType === 'INVOICES_AND_BILLS' && activity.invoice?.isDeleted === true && (
                                <div className="cursor-default rounded-full bg-red-500 px-5 py-3 transition-colors hover:bg-red-400">
                                    <p className="user-select-none font-semibold">{t('Invoice is deleted')}</p>
                                </div>
                            )} */}
                        </div>
                    </div>

                    {/* Action Arrow */}

                    <div className="absolute right-2 top-2">
                        {activity.actionType !== 'INVOICES_AND_BILLS' && (
                            <ActionArrow
                                actions={(() => {
                                    const canEditTasks =
                                        activity.actionType !== 'DOCUMENTS' &&
                                        activity.actionType !== 'FILES' &&
                                        actionArrow.edit;

                                    const canEditDocuments =
                                        (activity.actionType === 'DOCUMENTS' || activity.actionType === 'FILES') &&
                                        editDocuments;

                                    const displayArrow = actionArrow.edit || actionArrow.gantt || actionArrow.notify;

                                    return user.id === activity.authorId && displayArrow
                                        ? [
                                              {
                                                  name: t('Edit'),
                                                  render: canEditTasks || canEditDocuments,
                                                  action: () => {
                                                      setEditActivity();
                                                  },
                                              },
                                              {
                                                  name: t('Delete'),
                                                  render: canEditTasks || canEditDocuments,
                                                  action: () => {
                                                      globalContext.setGlobalModalOpen(true);
                                                      globalContext.setGlobalModalChildren(
                                                          <div className="flex-col">
                                                              <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                                  {t('Are you sure you want to')}
                                                                  <br />
                                                                  {t('delete this activity?')}
                                                              </p>
                                                              <div className="flex items-center justify-center">
                                                                  <Button
                                                                      color="primary"
                                                                      onClick={() => {
                                                                          deleteActivity(activity);
                                                                          globalContext.setGlobalModalOpen(false);
                                                                      }}
                                                                  >
                                                                      {t('Yes')}
                                                                  </Button>
                                                                  <div className="w-4" />
                                                                  <Button
                                                                      onClick={() =>
                                                                          globalContext.setGlobalModalOpen(false)
                                                                      }
                                                                  >
                                                                      {t('No')}
                                                                  </Button>
                                                              </div>
                                                          </div>,
                                                      );
                                                  },
                                              },
                                              {
                                                  name: 'Gantt',
                                                  render: actionArrow.gantt,
                                                  action: setSeeGantt,
                                              },
                                              {
                                                  name: t('Notify'),
                                                  render: actionArrow.notify,
                                                  action: () => onNotify(activity.id),
                                              },
                                          ]
                                        : [];
                                })()}
                            />
                        )}
                    </div>

                    <div className="mb-1.5 rounded-md bg-layout-transparent px-6 py-4 pt-10 text-main-text transition-colors sm:pt-24">
                        {/* Actitity name (header) */}
                        {activity.actionType === 'INVOICES_AND_BILLS' ? (
                            <h3 className="mb-5 font-semibold">{activity.invoice?.name}</h3>
                        ) : (
                            <h3 className="mb-5 font-semibold">{activity.title}</h3>
                        )}

                        {/* {activity.actionType === 'INVOICES_AND_BILLS' && (
                            <h3 className="mb-3 text-lg font-semibold italic">
                                {t('Invoice type')}: {invoiceTypeDisplayed(activity.invoice)}
                            </h3>
                        )} */}

                        {activity.actionType === 'INVOICES_AND_BILLS' && (
                            <h3 className="mb-5 text-lg">
                                {t('Invoice value')}: <span className="font-semibold">{activity.invoice?.value}</span>{' '}
                                {currencyObj.currency}
                            </h3>
                        )}

                        {/* Activity body */}
                        {activity.actionType === 'COMMENT' && (
                            <>{activity.description && <p className="mb-5">{activity.description}</p>}</>
                        )}
                        {activity.actionType === 'TASK' && (
                            <>
                                {activity.description && activity.activitiesTasks.length > 0 && (
                                    <iframe
                                        className="mb-5 h-64 w-full rounded-md border border-layout-lighter bg-white  p-2 md:h-96"
                                        srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(activity.description)}`}
                                    />
                                )}

                                {activity.description && activity.activitiesTasks.length === 0 && (
                                    <p className="mb-5 font-semibold">{activity.description}</p>
                                )}

                                {activity.activitiesTasks.length <= 0 && (
                                    <div className="mb-1 flex gap-2">
                                        <span className="text-dark-text">{t('Task interval')}:</span>
                                        <div className="font-bold opacity-70">{formatDate(activity.startDate)}</div>
                                        <span className="font-bold ">-</span>
                                        <div className="font-bold opacity-70">
                                            {formatDate(activity.endDate ? activity.endDate : activity.startDate)}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {(activity.actionType === 'FILES' || activity.actionType === 'DOCUMENTS') && (
                            <>
                                {activity.Files?.length > 0 && (
                                    <div className="mb-4">
                                        {activity.Files.map((file) => (
                                            <div
                                                key={file.id}
                                                className="mb-1 flex flex-wrap items-center justify-center gap-4 rounded-lg bg-layout-transparent p-1 transition-colors hover:bg-layout-transparent-dark"
                                            >
                                                <div
                                                    className={`flex w-36 flex-shrink-0 items-center rounded-md ${
                                                        activityBackgroundColors[activity.actionType]
                                                    } px-4 py-2`}
                                                >
                                                    <EuroSymbolIcon
                                                        className="mr-2 text-buttons-text"
                                                        style={{ fontSize: '1.5rem' }}
                                                    />
                                                    <p className="font-semibold text-buttons-text">
                                                        {activity.actionType === 'FILES'
                                                            ? t('Files')
                                                            : activity.actionType === 'DOCUMENTS'
                                                              ? t('Documents')
                                                              : t('Bills')}
                                                    </p>
                                                </div>
                                                <p className="flex-grow text-center font-semibold">{file.file.name}</p>

                                                <div className="flex flex-shrink-0 items-center gap-1 sm:gap-2">
                                                    <div className="group relative">
                                                        <a href={file.file.url} target="_blank" rel="noreferrer">
                                                            <BasicTooltip tip="Link">
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<LinkIcon />}
                                                                />
                                                            </BasicTooltip>
                                                        </a>
                                                    </div>

                                                    <div className="group relative mr-1">
                                                        <BasicTooltip tip={t('Download')}>
                                                            <a href={file.file.url} download={file.file.name}>
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<GetAppIcon />}
                                                                />
                                                            </a>
                                                        </BasicTooltip>
                                                    </div>

                                                    {/* {deleteIcon && (
                                                        <div className="relative group">
                                                            <BasicTooltip tip="Șterge">
                                                                <ActionButton onClick={onDelete} icon={<CloseIcon />} />
                                                            </BasicTooltip>
                                                        </div>
                                                    )} */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                        {/* {activity.actionType === 'INVOICES_AND_BILLS' && (
                            <>
                                {activity.Files?.length > 0 && (
                                    <div className="mb-4">
                                        {activity.Files.map((file) => (
                                            <div
                                                key={file.id}
                                                className="mb-1 flex flex-wrap items-center justify-center gap-4 rounded-lg bg-layout-transparent p-1 transition-colors hover:bg-layout-transparent-dark"
                                            >
                                                <div
                                                    className={`flex w-36 flex-shrink-0 items-center rounded-md ${
                                                        activityBackgroundColors[activity.actionType]
                                                    } px-4 py-2`}
                                                >
                                                    <EuroSymbolIcon
                                                        className="mr-2 text-white"
                                                        style={{ fontSize: '1.5rem' }}
                                                    />
                                                    <p className="font-semibold">
                                                        {activity.actionType === 'FILES'
                                                            ? t('Files')
                                                            : activity.actionType === 'DOCUMENTS'
                                                            ? t('Documents')
                                                            : t('Bills')}
                                                    </p>
                                                </div>
                                                <p className="flex-grow text-center font-semibold">{file.file.name}</p>

                                                <div className="flex flex-shrink-0 items-center gap-1 sm:gap-2">
                                                    <div className="group relative mr-1">
                                                        <a href={file.file.url} target="_blank" rel="noreferrer">
                                                            <BasicTooltip tip="Link">
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<LinkIcon />}
                                                                />
                                                            </BasicTooltip>
                                                        </a>
                                                    </div>

                                                    <div className="group relative mr-1">
                                                        <BasicTooltip tip={t('Download')}>
                                                            <a href={file.file.url} download={file.file.name}>
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<GetAppIcon />}
                                                                />
                                                            </a>
                                                        </BasicTooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )} */}
                        {/* Activity footer */}
                        {/* Activity Author */}
                        <div className="mb-5 flex items-center">
                            <div className="mr-5">
                                <span className="pointer-events-none text-dark-text">{t('Activity author')}: </span>
                                <span
                                    className={`font-semibold ${userLink && linkStyle}`}
                                    onClick={() => {
                                        if (!userLink) return;
                                        isContact
                                            ? history.push(
                                                  crm.base + crm.contactActivity.base + '/' + activity.authorId,
                                              )
                                            : history.push(
                                                  configurator.base +
                                                      configurator.users.base +
                                                      configurator.users.update +
                                                      '/' +
                                                      activity.authorId +
                                                      '?tab=1',
                                              );
                                    }}
                                >
                                    {activity.author?.profile?.name}
                                </span>
                            </div>

                            {/* Activity Asignee */}
                            {activity.responsible && (
                                <div>
                                    <span className="pointer-events-none opacity-50">{t('Responsible')}: </span>
                                    <span
                                        className={`font-semibold ${linkStyle}`}
                                        onClick={
                                            () => null
                                            // history.push(crm.base + crm.contactActivity.base + '/' + activity.owner.id)
                                        }
                                    >
                                        {activity.responsible.email}
                                    </span>
                                </div>
                            )}
                        </div>
                        {/* Add a comment */}
                        {commentView && (
                            <AddComment
                                onSubmit={(newComment, newCommentFiles) =>
                                    submitHandle(activity.id, newComment, newCommentFiles)
                                }
                            />
                        )}
                    </div>

                    {/* Comment section */}
                    {activity.comments?.length > 0 && (
                        <div className="relative">
                            {activity.comments.map((comment, index) => (
                                <div
                                    key={comment.id}
                                    style={{
                                        // zIndex: activity.comments.length - index + 1,
                                        position: 'relative',
                                    }}
                                >
                                    <Comment userId={user.id} comment={comment} commentView={commentView} />
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Submilestone section */}
                    {activity.actionType === 'TASK' && activity.activitiesTasks?.length > 0 && (
                        <div className="relative">
                            {activity.activitiesTasks.map((submilestone, index) => (
                                <div
                                    key={submilestone.id}
                                    style={{
                                        // zIndex: activity.activitiesTasks.length - index,
                                        position: 'relative',
                                    }}
                                >
                                    <SubilestoneDisplay submilestone={submilestone} milestoneCheck={milestoneCheck} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

Activity.propTypes = {
    onLink: PropTypes.func,
    onDownload: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onNotify: PropTypes.func,
    activity: PropTypes.object,
    deleteActivity: PropTypes.func,
    updateActivity: PropTypes.func,
    setUpdatedActivity: PropTypes.func,
    actionArrow: PropTypes.object,
    deleteIcon: PropTypes.bool,
    commentView: PropTypes.bool,
    milestoneCheck: PropTypes.bool,
    check: PropTypes.bool,
    userLink: PropTypes.bool,
    setSeeGantt: PropTypes.func.isRequired,
    setEditActivity: PropTypes.func,
    editDocuments: PropTypes.bool,
};

Activity.defaultProps = {
    onLink: () => null,
    onDownload: () => null,
    onEdit: () => null,
    onDelete: () => null,
    onNotify: () => null,
    activity: null,
    deleteActivity: () => null,
    updateActivity: () => null,
    setUpdatedActivity: () => null,
    actionArrow: {
        edit: false,
        gantt: false,
        notify: false,
    },
    deleteIcon: false,
    commentView: false,
    milestoneCheck: false,
    check: false,
    userLink: false,
    setEditActivity: () => null,
    editDocuments: false,
};

export default Activity;
