import React from 'react';

import FileRow from 'components/shared/file-row/file-row';
import GradientButton from 'components/shared/gradient-button';
import PropTypes from 'prop-types';

import PipelineItemRow from './pipeline-item-row';

const PipelineItemBody = ({ itemProps, item, open }) => {
    const visibleRows = itemProps.body.renderVisibleRows(item);
    const extendedRows = itemProps.body.renderExpandedRows(item);

    const {
        canRender: canRenderButtonFn = () => false,
        render: renderButton,
        icon,
        action,
        disabled: disabledButton,
    } = itemProps.button || {};

    const {
        canRender: canRenderFileFn = () => false,
        render: renderFile,
        info,
        actions,
        disabled: disabledFile,
    } = itemProps.file || {};

    const canRenderButton = canRenderButtonFn(item);
    const canRenderFile = canRenderFileFn(item);
    const canExtend = extendedRows.length + Number(canRenderButton) + Number(canRenderFile) > 0;

    return (
        <div className="flex flex-col items-center">
            {visibleRows.map((row, index) => {
                if (!row.canRender) return null;
                return (
                    <PipelineItemRow
                        key={index}
                        row={row}
                        className={index !== visibleRows.length - 1 ? 'mb-2.5' : ''}
                    />
                );
            })}

            {canExtend && (
                <div
                    className={`grid w-full transform transition-all duration-300 ${
                        open ? 'translate-y-2.5 pb-2.5' : 'translate-y-0'
                    }`}
                    style={{
                        gridTemplateRows: open ? '1fr' : `0fr`,
                    }}
                >
                    <div className="flex flex-col items-center gap-y-2.5 overflow-hidden">
                        {extendedRows.map((row, index) => {
                            if (!row.canRender) return null;
                            return <PipelineItemRow key={index} row={row} />;
                        })}
                        {(canRenderFile || canRenderButton) && (
                            <div className="mt-5 flex w-full flex-col gap-4">
                                {canRenderFile && (
                                    <FileRow
                                        actions={actions.map((element) => ({
                                            ...element,
                                            action: element.action.bind(null, item),
                                        }))}
                                        info={info}
                                        disabled={disabledFile}
                                    >
                                        {renderFile(item)}
                                    </FileRow>
                                )}
                                {canRenderButton && (
                                    <GradientButton
                                        onClick={() => action(item)}
                                        disabled={disabledButton}
                                        startIcon={icon}
                                        size="large"
                                        className="h-16"
                                    >
                                        {renderButton()}
                                    </GradientButton>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

PipelineItemBody.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    open: PropTypes.bool,
};

PipelineItemBody.defaultProps = {
    itemProps: {},
    item: {},
    open: false,
};

export default PipelineItemBody;
