import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';

import { Button, makeStyles, TextField } from '@material-ui/core';

import { ActionArrow } from 'components/internal-activity/activity';
import AddComment from 'components/internal-activity/add-comment';
import BasicTooltip from 'components/shared/basic-tooltip';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown, Toggle } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { crm, quotes } from 'routes';
import { errorHandling, formatDate, formatTime, linkStyle } from 'utils';
import API from 'utils/axios';

const useStyles = makeStyles(() => {
    return {
        sent: {
            backgroundColor: `#1D8CF8`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#1D8CF8`,
            },
        },
        draft: {
            backgroundColor: `#FB924B`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#FB924B`,
            },
        },
        lost: {
            backgroundColor: `#A47DEF`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#A47DEF`,
            },
        },
        blocked: {
            backgroundColor: `#3587A4`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#3587A4`,
            },
        },
        accepted: {
            backgroundColor: `#3EC356`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#3EC356`,
            },
        },
    };
});

const OfferItem = (props) => {
    const { offerInfo } = props;
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className="mb-2 flex items-center rounded-md bg-layout-transparent p-3 transition-colors last:mb-0 hover:bg-primary-transparent">
            <AttachFileIcon className="mr-2 rotate-45 transform text-buttons-text" />

            <div className="grid w-full grid-cols-2 items-center gap-6">
                <div>
                    <p className="font-semibold text-buttons-text">{offerInfo.name}</p>
                    <p className="text-buttons-text">
                        <span className="font-bold text-buttons-text">{t('Offer details')}: </span>
                        {offerInfo.description}
                    </p>
                </div>
                {offerInfo.status !== 'DRAFT' && (
                    <div className="flex items-center justify-end gap-6">
                        <p className="text-center font-bold text-buttons-text">
                            {t('Number of revisions')}:{' '}
                            <span className="font-normal text-buttons-text">
                                {offerInfo.metadata.followUpOffersLength}
                            </span>
                        </p>
                        <div className="flex items-center gap-2">
                            <BasicTooltip tip={t('View offer')}>
                                <div
                                    className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-primary-main hover:bg-primary-light "
                                    onClick={() => {
                                        history.push(
                                            quotes.base +
                                                quotes.viewBidding.base +
                                                '/' +
                                                offerInfo.metadata.originalOfferId,
                                        );
                                    }}
                                >
                                    <LibraryBooksIcon className="text-buttons-text" style={{ fontSize: '1.25rem' }} />
                                </div>
                            </BasicTooltip>
                            {offerInfo.pdfUrl && (
                                <div className="flex items-center">
                                    <BasicTooltip tip={t('Download offer')}>
                                        <a href={`${offerInfo.pdfUrl}`} rel="noreferrer" download>
                                            <div className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-primary-main hover:bg-primary-light ">
                                                <GetAppIcon
                                                    className="text-buttons-text"
                                                    style={{ fontSize: '1.25rem' }}
                                                />
                                            </div>
                                        </a>
                                    </BasicTooltip>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

OfferItem.propTypes = {
    offerInfo: PropTypes.object,
};

OfferItem.defaultProps = {
    offerInfo: null,
};

export const CommentOffer = (props) => {
    const { comment, pageLayout, disabled } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const deleteComment = async () => {
        if (isDeleting) return;

        setIsDeleting(true);
        try {
            await API.delete('offerComment', {
                data: {
                    id: comment.id,
                },
            });
            enqueueSnackbar(t('Comment deleted!'), { variant: 'success' });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            {comment !== null && (
                <div
                    className={`relative mb-1.5 flex flex-col gap-2 bg-layout-transparent px-8 py-3 transition-colors hover:bg-layout-transparent-dark ${
                        isDeleting ? 'hidden' : 'block'
                    } ${pageLayout ? 'rounded-md' : 'rounded-br-md rounded-tr-md border-l border-secondary-light'}`}
                >
                    <div className="absolute right-2 top-2">
                        <ActionArrow
                            actions={[
                                {
                                    name: t('Delete'),
                                    action: deleteComment,
                                    render: !disabled,
                                },
                            ]}
                        />
                    </div>

                    {comment.comment !== null && comment.comment !== '' && (
                        <p className="mb-2 text-main-text">{comment.comment}</p>
                    )}
                    <p className="text-dark-text">
                        <span className="font-semibold">{comment.tenantAccounts.profile.name}</span>{' '}
                        {`${formatDate(comment.createAt)} ${formatTime(comment.createAt)}`}
                    </p>
                </div>
            )}
        </>
    );
};

CommentOffer.propTypes = {
    comment: PropTypes.object,
    pageLayout: PropTypes.bool,
    disabled: PropTypes.bool,
};

CommentOffer.defaultProps = {
    comment: null,
    pageLayout: false,
    disabled: false,
};

const offerImportanceUrgencyMessages = {
    important: 'The importance status of the offer has been successfully updated!',
    urgent: 'The urgency status of the offer has been successfully updated!',
};

const Quote = (props) => {
    const { t } = useTranslation();
    const styles = useStyles();

    const { offer, fetchHistory } = props;

    const [actualOffer, setActualOffer] = useState(offer.followUpOffers.at(-1) ?? offer);
    const clientData =
        typeof actualOffer.contact.data === 'string'
            ? JSON.parse(actualOffer.contact.data).standard
            : actualOffer.contact.data.standard;
    const user = actualOffer.author.profile;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [status, setStatus] = useState('NEW');

    const [lostOfferReasons, setLostOfferReasons] = useState([]);
    const [lostReasons, setLostReasons] = useState([]);

    const [newReason, setNewReason] = useState(null);
    const [newReasonField, setNewReasonField] = useState(false);

    const lastHistoryPos = actualOffer.offerHistory.length - 1;

    const { checkPerm } = useContext(UserContext);

    const { language } = useContext(GlobalContext);

    const canAll = checkPerm([
        {
            permissionId: '9',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '9',
            permissionType: 'VIEW',
        },
    ]);

    const canAllContract = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const defaultKeys = [
        'The price was too high',
        'The client changed his mind',
        'The client could not pay',
        'Add new reason',
    ];

    useEffect(() => {
        const defaultReasons = [
            t('The price was too high'),
            t('The client changed his mind'),
            t('The client could not pay'),
            t('Add new reason'),
        ];

        if (actualOffer.offerArticles.some((a) => a.articleStatus !== null)) {
            defaultReasons.push(t('The primary offer was accepted'));
            [defaultReasons[defaultReasons.length - 1], defaultReasons[defaultReasons.length - 2]] = [
                defaultReasons[defaultReasons.length - 2],
                defaultReasons[defaultReasons.length - 1],
            ];
        }

        const newReasonList = [...defaultReasons];
        let newLostOfferReasons = [];

        setStatus(actualOffer.offerHistory[lastHistoryPos].status);

        actualOffer.offerHistory[lastHistoryPos].reasons.forEach((r) => {
            if (newReasonList.indexOf(t(r.reasonMessage)) === -1) {
                if (r.reasonMessage !== '') {
                    newReasonList[newReasonList.length - 1] = r.reasonMessage;
                    newReasonList.push(t('Add new reason'));
                    newLostOfferReasons.push(newReasonList.indexOf(t(r.reasonMessage)));
                }
            } else {
                newLostOfferReasons.push(newReasonList.indexOf(t(r.reasonMessage)));
            }
        });

        setLostReasons(newReasonList);
        setLostOfferReasons(newLostOfferReasons);
    }, [actualOffer, language]);

    const updateOfferStatus = async (offerId, offerStatus, statusMessage, reasonsList) => {
        await API.put('offerHistory', {
            offerId: offerId,
            status: offerStatus,
            message: statusMessage,
            reasons: reasonsList,
        });
    };

    const handleChangeBlockStatus = async () => {
        await updateOfferStatus(
            actualOffer.id,
            status !== 'BLOCKED' ? 'BLOCKED' : actualOffer.offerHistory[lastHistoryPos - 1].status,
            status !== 'BLOCKED' ? 'Offer blocked' : actualOffer.offerHistory[lastHistoryPos - 1].message,
            status !== 'BLOCKED'
                ? []
                : actualOffer.offerHistory[lastHistoryPos - 1].reasons.map((r) => r.reasonMessage),
        );

        fetchHistory();
    };

    const submitComment = async (offerId, newComment) => {
        if (!newComment.length) return;
        try {
            await API.post('offerComment', {
                comment: newComment,
                offerId: offerId,
            });
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            console.error(err);
        } finally {
            fetchHistory();
        }
    };

    const handleUpdateOfferImportanceUrgency = async (key, value) => {
        try {
            await API.patch('offer_importance_urgency', undefined, { params: { id: actualOffer.id, [key]: value } });

            setActualOffer((prev) => ({ ...prev, [key]: value }));

            enqueueSnackbar(t(offerImportanceUrgencyMessages[key]), { variant: 'success' });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    return (
        <>
            {canView && (
                <div className="relative mb-12 last:mb-0">
                    {/*
                Quote client Status 
            */}
                    <div
                        className="absolute left-8 top-0 -translate-y-1/2 transform rounded-full px-5 py-3 transition-colors sm:left-4"
                        style={{
                            backgroundColor:
                                status === 'NEW' && actualOffer.status !== 'DRAFT'
                                    ? '#1D8CF8'
                                    : status === 'NEW' && actualOffer.status === 'DRAFT'
                                      ? '#FB924B'
                                      : status === 'BLOCKED'
                                        ? '#3587A4'
                                        : status === 'LOST' || status === 'NOT_ACCEPTED'
                                          ? '#A47DEF'
                                          : '#3EC356',
                        }}
                    >
                        <p className="user-select-none font-semibold text-buttons-text">{clientData.name}</p>
                    </div>

                    <div className="absolute right-10 top-0 flex gap-2 sm:right-4">
                        {actualOffer.OfTags.map((tag) => (
                            <div
                                key={tag.id}
                                className="-translate-y-1/2 transform rounded-full bg-secondary-main px-5 py-2 font-semibold text-buttons-text transition-colors"
                            >
                                {tag.offerTags.name}
                            </div>
                        ))}
                    </div>
                    {/*
                Quote icon to the left
            */}

                    <div className="absolute -left-8 top-5 h-12 w-12 -translate-x-1/2 transform rounded-full bg-layout-main sm:-left-6">
                        <LocalOfferIcon
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color:
                                    status === 'NEW' && actualOffer.status !== 'DRAFT'
                                        ? '#1D8CF8'
                                        : status === 'NEW' && actualOffer.status === 'DRAFT'
                                          ? '#FB924B'
                                          : status === 'BLOCKED'
                                            ? '#3587A4'
                                            : status === 'LOST' || status === 'NOT_ACCEPTED'
                                              ? '#A47DEF'
                                              : '#3EC356',
                                fontSize: '1.5rem',
                            }}
                        />

                        <div className="absolute top-1/2 -translate-x-full -translate-y-1/2 transform pr-5 text-right sm:flex sm:translate-x-quote-date sm:translate-y-1 sm:gap-6 sm:pr-0">
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right font-semibold">
                                {formatDate(actualOffer.createAt)}
                            </p>
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right text-dark-text">
                                {formatTime(actualOffer.createAt)}
                            </p>
                        </div>
                    </div>

                    {/* 
                Quote Body & Contents
            */}
                    <div className="mb-1.5 rounded-md bg-layout-transparent px-8 pb-5 pt-10 text-main-text transition-colors sm:px-4 sm:pt-24">
                        {/* Offers */}
                        <div className="mb-9">
                            <OfferItem
                                offerInfo={{
                                    ...actualOffer,
                                    metadata: {
                                        originalOfferId: offer.id,
                                        followUpOffersLength: offer.followUpOffers.length,
                                    },
                                }}
                            />
                        </div>

                        {/* User roles */}
                        <div className="mb-8 flex flex-wrap items-center justify-between gap-4">
                            <div className="flex flex-row flex-wrap gap-6">
                                <p className="text-dark-text">
                                    {t('Author')}:{' '}
                                    <span className={`${linkStyle} font-bold text-main-text`}>{user.name}</span>
                                </p>
                                <p className="text-dark-text">
                                    {t('To')}:{' '}
                                    <span className={`${linkStyle} font-bold text-main-text`}>{clientData.email}</span>
                                </p>
                            </div>

                            {status !== 'LOST' && status !== 'NOT_ACCEPTED' && status !== 'BLOCKED' && (
                                <div className="flex gap-4">
                                    <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2.5">
                                        <Toggle
                                            checked={actualOffer.important}
                                            setChecked={() =>
                                                handleUpdateOfferImportanceUrgency('important', !actualOffer.important)
                                            }
                                        />
                                        <p>{t('Important')}</p>
                                    </div>
                                    <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2.5">
                                        <Toggle
                                            checked={actualOffer.urgent}
                                            setChecked={() =>
                                                handleUpdateOfferImportanceUrgency('urgent', !actualOffer.urgent)
                                            }
                                        />
                                        <p>{t('Urgent')}</p>
                                    </div>
                                </div>
                            )}
                        </div>

                        {canAll && (
                            <div className="mb-5 flex flex-wrap items-start gap-4 sm:flex-col">
                                {canAllContract && status === 'NEW' && actualOffer.status !== 'DRAFT' && (
                                    <BasicTooltip
                                        tip={t(
                                            "You can't accept this offer because it contains an article on another offer which has the priority",
                                        )}
                                        disabled={actualOffer.offerArticles.every((a) => a.articleStatus !== 'WAITING')}
                                    >
                                        <Button
                                            className={styles.sent}
                                            startIcon={<EditIcon />}
                                            disabled={
                                                !!actualOffer.offerArticles.find((a) => a.articleStatus === 'WAITING')
                                            }
                                            onClick={async () => {
                                                await updateOfferStatus(
                                                    actualOffer.id,
                                                    'ACCEPTED',
                                                    'Offer was accepted',
                                                    [],
                                                );

                                                fetchHistory();

                                                history.push(
                                                    crm.base +
                                                        crm.contracts.base +
                                                        crm.contracts.create +
                                                        `?offerId=${actualOffer.id}`,
                                                );
                                            }}
                                        >
                                            {t('Accept the offer and create the contract')}
                                        </Button>
                                    </BasicTooltip>
                                )}

                                {canAllContract && status === 'ACCEPTED' && (
                                    <Button
                                        className={styles.accepted}
                                        startIcon={<EditIcon />}
                                        onClick={() => {
                                            if (actualOffer.contracts.length > 0) {
                                                history.push(
                                                    crm.base +
                                                        crm.contracts.base +
                                                        crm.contracts.update +
                                                        '/' +
                                                        actualOffer.contracts[0].id,
                                                );
                                            } else {
                                                history.push(
                                                    crm.base +
                                                        crm.contracts.base +
                                                        crm.contracts.create +
                                                        `?offerId=${actualOffer.id}`,
                                                );
                                            }
                                        }}
                                    >
                                        {(() => {
                                            let string;
                                            if (actualOffer.contracts.length > 0) {
                                                string = t('See contract');
                                            } else {
                                                string = t('Create the contract');
                                            }
                                            return string;
                                        })()}
                                    </Button>
                                )}

                                {status === 'NEW' && actualOffer.status === 'DRAFT' && (
                                    <Button
                                        className={styles.draft}
                                        startIcon={<LocalOfferIcon />}
                                        onClick={() => {
                                            const search = new URLSearchParams();
                                            search.set('id', actualOffer.id);

                                            history.push({
                                                pathname: quotes.base + quotes.newBidding.base + quotes.newBidding.read,
                                                search: search.toString(),
                                            });
                                        }}
                                    >
                                        {t('Finish the offer')}
                                    </Button>
                                )}

                                {status === 'NEW' && actualOffer.status !== 'DRAFT' && (
                                    <Button
                                        startIcon={<CancelIcon />}
                                        className={styles.lost}
                                        onClick={async () => {
                                            await updateOfferStatus(
                                                actualOffer.id,
                                                'NOT_ACCEPTED',
                                                'Offer was not accepted',
                                                [t('Offer was not accepted')],
                                            );

                                            fetchHistory();
                                        }}
                                    >
                                        {t('Refuse the offer')}
                                    </Button>
                                )}

                                {status !== 'LOST' && status !== 'NOT_ACCEPTED' && (
                                    <Button
                                        className={styles.blocked}
                                        startIcon={status !== 'BLOCKED' ? <LockIcon /> : <LockOpenIcon />}
                                        onClick={handleChangeBlockStatus}
                                    >
                                        {status !== 'BLOCKED' ? t('Block the offer') : t('Unblock the offer')}
                                    </Button>
                                )}

                                {(status === 'NEW' || status === 'LOST' || status === 'NOT_ACCEPTED') &&
                                    actualOffer.status !== 'DRAFT' && (
                                        <div className="flex items-end gap-4 rounded-md bg-layout-transparent p-2 sm:flex-col">
                                            {!newReasonField && (
                                                <div style={{ zIndex: 9999 }}>
                                                    <LabelWrapper
                                                        label={t('Choose the reasons for the lost of the offer')}
                                                    >
                                                        <MultiDropdown
                                                            placeholder={t(
                                                                'Choose the reasons for the lost of the offer',
                                                            )}
                                                            options={lostReasons}
                                                            selectedOptions={lostOfferReasons}
                                                            setSelectedOptions={(newId) => {
                                                                if (newId === lostReasons.length - 1) {
                                                                    setNewReasonField(!newReasonField);
                                                                } else {
                                                                    if (lostOfferReasons.indexOf(newId) > -1) {
                                                                        setLostOfferReasons(
                                                                            lostOfferReasons.filter(
                                                                                (opt) => opt !== newId,
                                                                            ),
                                                                        );
                                                                    } else {
                                                                        setLostOfferReasons([
                                                                            ...lostOfferReasons,
                                                                            newId,
                                                                        ]);
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </LabelWrapper>
                                                </div>
                                            )}

                                            {newReasonField && (
                                                <div className="flex flex-col gap-2">
                                                    <TextField
                                                        name="new reason"
                                                        label={t('Reason')}
                                                        placeholder={t('Reason')}
                                                        value={newReason}
                                                        onChange={(e) => setNewReason(e.target.value)}
                                                    />

                                                    <div className="flex justify-end gap-2">
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                const newReasonList = [...lostReasons];
                                                                const listLen = newReasonList.length;
                                                                if (newReason == null) {
                                                                    setNewReasonField(!newReasonField);
                                                                    return;
                                                                } else {
                                                                    newReasonList[listLen - 1] = newReason;
                                                                }
                                                                newReasonList.push(t('Add new reason'));
                                                                setLostReasons(newReasonList);
                                                                setNewReason('');
                                                                setLostOfferReasons([
                                                                    ...lostOfferReasons,
                                                                    newReasonList.indexOf(newReason),
                                                                ]);
                                                                setNewReasonField(false);

                                                                setNewReason(null);
                                                                setNewReasonField(!newReasonField);
                                                            }}
                                                        >
                                                            {t('Add new reason')}
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            color="secondary"
                                                            onClick={() => setNewReasonField(false)}
                                                        >
                                                            {t('Cancel')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}

                                            {status === 'NEW' && actualOffer.status !== 'DRAFT' && (
                                                <Button
                                                    disabled={lostOfferReasons.length === 0 || newReasonField}
                                                    startIcon={<ThumbDownAltIcon />}
                                                    className={styles.lost}
                                                    onClick={async () => {
                                                        await updateOfferStatus(
                                                            actualOffer.id,
                                                            'LOST',
                                                            'Offer lost',
                                                            lostOfferReasons
                                                                .sort((a, b) => a - b)
                                                                .map((r) => {
                                                                    if (r > 2) {
                                                                        return lostReasons[r];
                                                                    }
                                                                    return defaultKeys[r];
                                                                }),
                                                        );

                                                        fetchHistory();
                                                    }}
                                                >
                                                    {t('Mark the offer as lost')}
                                                </Button>
                                            )}

                                            {(status === 'LOST' || status === 'NOT_ACCEPTED') && (
                                                <Button
                                                    disabled={lostOfferReasons.length === 0 || newReasonField}
                                                    className={styles.lost}
                                                    startIcon={<AutorenewIcon />}
                                                    onClick={async () => {
                                                        await updateOfferStatus(
                                                            actualOffer.id,
                                                            status,
                                                            'Offer lost',
                                                            lostOfferReasons
                                                                .sort((a, b) => a - b)
                                                                .map((r) => lostReasons[r]),
                                                        );

                                                        fetchHistory();
                                                    }}
                                                >
                                                    {t('Update the reasons')}
                                                </Button>
                                            )}
                                        </div>
                                    )}
                            </div>
                        )}

                        {canAll && (
                            <AddComment
                                onSubmit={(newComment) => submitComment(actualOffer.id, newComment)}
                                fileIconView={false}
                                onOffer={true}
                                disabled={status === 'BLOCKED'}
                            />
                        )}
                    </div>
                    {actualOffer.OfferComments.length > 0 && (
                        <div className="relative">
                            {actualOffer.OfferComments.map((comment, index) => (
                                <div
                                    key={index}
                                    style={{
                                        zIndex: actualOffer.OfferComments.length - index + 1,
                                        position: 'relative',
                                    }}
                                >
                                    <CommentOffer comment={comment} disabled={status === 'BLOCKED'} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

Quote.propTypes = {
    offer: PropTypes.object,
    fetchHistory: PropTypes.func,
};

Quote.defaultProps = {
    offer: null,
    fetchHistory: () => null,
};

export default Quote;
