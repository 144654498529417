import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';

import { Button, makeStyles, TextField } from '@material-ui/core';

import { CommentOffer } from 'components/crm/quotes/quote';
import AddComment from 'components/internal-activity/add-comment';
import BasicTooltip from 'components/shared/basic-tooltip';
import Loading from 'components/shared/loading';
import SuccessButton from 'components/shared/success-button';
import OfferContext from 'contexts/OfferContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header, LabelWrapper, MultiDropdown, Tabs, Toggle } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm, quotes } from 'routes';
import { errorHandling, formatDate } from 'utils';
import API from 'utils/axios';

import OfferArticles from './offer-articles';
import OfferDetails from './offer-details';
import OfferPDF from './offer-pdf';
import OfferSIP from './offer-sip';
import OfferTags from './offer-tags';

const useStyles = makeStyles(() => {
    return {
        sent: {
            backgroundColor: `#1D8CF8`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#1D8CF8`,
            },
        },
        draft: {
            backgroundColor: `#FB924B`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#FB924B`,
            },
        },
        lost: {
            backgroundColor: `#A47DEF`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#A47DEF`,
            },
        },
        blocked: {
            backgroundColor: `#3587A4`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#3587A4`,
            },
        },
        accepted: {
            backgroundColor: `#3EC356`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#3EC356`,
            },
        },
    };
});

const defaultOfferContextValue = {
    step: 0,
    offerId: undefined,
    offer: null,
    pdfInfo: { items: [] },
};

const defaultKeys = [
    'The price was too high',
    'The client changed his mind',
    'The client could not pay',
    'Add new reason',
];

const offerImportanceUrgencyMessages = {
    important: 'The importance status of the offer has been successfully updated!',
    urgent: 'The urgency status of the offer has been successfully updated!',
};

const OfferView = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const history = useHistory();
    const styles = useStyles();

    const defaultReasons = [
        t('The price was too high'),
        t('The client changed his mind'),
        t('The client could not pay'),
        t('Add new reason'),
    ];

    const { checkPerm } = useContext(UserContext);

    const [selectedOffer, setSelectedOffer] = useState(null);
    const [offerRevisions, setOfferRevisions] = useState([]);
    const [offerContext, setOfferContext] = useState(defaultOfferContextValue);
    const editOfferContext = (key, value) => setOfferContext((prev) => ({ ...prev, [key]: value }));

    const [loading, setLoading] = useState(true);
    const [isOperating, setIsOperating] = useState(false);
    const [isChanging, setIsChanging] = useState(false);
    const isDisabled = isOperating || isChanging;

    const [lostOfferReasons, setLostOfferReasons] = useState([]);
    const [lostReasons, setLostReasons] = useState([]);

    const [newReason, setNewReason] = useState(null);
    const [newReasonField, setNewReasonField] = useState(false);

    const canAll = checkPerm([
        {
            permissionId: '9',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '9',
            permissionType: 'VIEW',
        },
    ]);

    const canAllContract = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const updateOfferStatus = async (offerId, offerStatus, statusMessage, reasonsList) => {
        try {
            await API.put('offerHistory', {
                offerId: offerId,
                status: offerStatus,
                message: statusMessage,
                reasons: reasonsList,
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleChangeBlockStatus = async () => {
        try {
            setIsOperating(true);

            const lastOfferHistory = offerContext.offer.offerHistory.at(-1);
            const secondLastOfferHistory = offerContext.offer.offerHistory.at(-2);

            await updateOfferStatus(
                offerContext.offerId,
                lastOfferHistory.status !== 'BLOCKED' ? 'BLOCKED' : secondLastOfferHistory.status,
                lastOfferHistory.status !== 'BLOCKED' ? 'Offer blocked' : secondLastOfferHistory.message,
                lastOfferHistory.status !== 'BLOCKED' ? [] : secondLastOfferHistory.reasons.map((r) => r.reasonMessage),
            );

            await getOffer(offerContext.offerId, true);

            enqueueSnackbar(
                t(
                    lastOfferHistory.status !== 'BLOCKED'
                        ? 'The offer was successfully blocked!'
                        : 'The offer was successfully unblocked!',
                ),
                { variant: 'success' },
            );
        } catch (error) {
            console.error(error);
        } finally {
            setIsOperating(false);
        }
    };

    const handleAcceptOffer = async () => {
        try {
            setIsOperating(true);

            await updateOfferStatus(offerContext.offerId, 'ACCEPTED', 'Offer was accepted', []);

            enqueueSnackbar(t('The offer was successfully accepted!'), { variant: 'success' });

            history.push(crm.base + crm.contracts.base + crm.contracts.create + `?offerId=${offerContext.offerId}`);
        } catch (error) {
            console.error(error);
        } finally {
            setIsOperating(false);
        }
    };

    const handleLoseOffer = async () => {
        try {
            setIsOperating(true);

            await updateOfferStatus(
                offerContext.offerId,
                'LOST',
                'Offer lost',
                lostOfferReasons
                    .sort((a, b) => a - b)
                    .map((r) => {
                        if (r > 2) {
                            return lostReasons[r];
                        }
                        return defaultKeys[r];
                    }),
            );

            await getOffer(offerContext.offerId, true);

            enqueueSnackbar(t('The offer was successfully declined!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
        } finally {
            setIsOperating(false);
        }
    };

    const handleDeclineOffer = async () => {
        try {
            setIsOperating(true);

            await updateOfferStatus(offerContext.offerId, 'NOT_ACCEPTED', 'Offer was not accepted', [
                t('Offer was not accepted'),
            ]);

            await getOffer(offerContext.offerId, true);

            enqueueSnackbar(t('The offer was successfully declined!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
        } finally {
            setIsOperating(false);
        }
    };

    const handleUpdateReasons = async () => {
        try {
            setIsOperating(true);

            await updateOfferStatus(
                offerContext.offerId,
                offerContext.offer.offerHistory.at(-1).status,
                'Offer lost',
                lostOfferReasons.sort((a, b) => a - b).map((r) => lostReasons[r]),
            );

            await getOffer(offerContext.offerId, true);

            enqueueSnackbar(t('Reasons for losing the offer have been successfully updated!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
        } finally {
            setIsOperating(false);
        }
    };

    const handleSetReasonsStates = (offer) => {
        if (offer.offerArticles.some((a) => a.articleStatus !== null)) {
            defaultReasons.push(t('The primary offer was accepted'));
            [defaultReasons[defaultReasons.length - 1], defaultReasons[defaultReasons.length - 2]] = [
                defaultReasons[defaultReasons.length - 2],
                defaultReasons[defaultReasons.length - 1],
            ];
        }

        const newReasonList = [...defaultReasons];
        let newLostOfferReasons = [];

        offer.offerHistory[offer.offerHistory.length - 1].reasons.forEach((r) => {
            if (newReasonList.indexOf(t(r.reasonMessage)) === -1) {
                if (r.reasonMessage !== '') {
                    newReasonList[newReasonList.length - 1] = r.reasonMessage;
                    newReasonList.push(t('Add new reason'));
                    newLostOfferReasons.push(newReasonList.indexOf(t(r.reasonMessage)));
                }
            } else {
                newLostOfferReasons.push(newReasonList.indexOf(t(r.reasonMessage)));
            }
        });

        setLostReasons(newReasonList);
        setLostOfferReasons(newLostOfferReasons);
    };

    const getOffer = async (id, canUpdateDisplayedOffer) => {
        try {
            const res = await API.get('offer', {
                params: {
                    id,
                },
            });

            const resOffer = res.data.offer;

            handleSetReasonsStates(resOffer);

            if (canUpdateDisplayedOffer) {
                editOfferContext('offer', resOffer);
                setOfferRevisions((prev) => {
                    const clonedPrev = structuredClone(prev);
                    clonedPrev.slice(prev.length - 1, 1, resOffer);
                    return clonedPrev;
                });
            }

            return resOffer;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleChangeRevision = async (index) => {
        try {
            setIsChanging(true);
            setSelectedOffer(index);

            const res = await getOffer(offerRevisions[index].id);
            editOfferContext('offerId', offerRevisions[index].id);
            editOfferContext('offer', res);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        } finally {
            setIsChanging(false);
        }
    };

    const handleMarkRevisionAsHead = async () => {
        try {
            setIsChanging(true);

            const res = await API.post('offer_duplicate', undefined, {
                params: {
                    id: offerContext.offerId,
                },
            });
            const resOffer = res.data.offer;

            handleSetReasonsStates(resOffer);

            setOfferRevisions((prev) => {
                const newRevisions = [...prev, resOffer];
                setSelectedOffer(newRevisions.length - 1);
                return newRevisions;
            });

            editOfferContext('offerId', resOffer.id);
            editOfferContext('offer', resOffer);

            enqueueSnackbar(t('The new revision was successfully created!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        } finally {
            setIsChanging(false);
        }
    };

    const handleCreateNewRevision = async () => {
        try {
            const res = await API.post('offer_duplicate', undefined, { params: { id: offerContext.offerId } });

            const search = new URLSearchParams();
            search.set('id', res.data.duplicatedOffer.id);

            enqueueSnackbar(
                t(
                    'A new revision was initialized. To complete the revision complete all the steps by making all the necessary changes!',
                ),
                { variant: 'warning' },
            );

            history.push({
                pathname: quotes.base + quotes.newBidding.base + quotes.newBidding.read,
                search: search.toString(),
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        }
    };

    const submitComment = async (comment) => {
        if (!comment.length) return;
        try {
            await API.post('offerComment', {
                comment,
                offerId: offerContext.offerId,
            });

            await getOffer(offerContext.offerId, true);

            enqueueSnackbar(t('The comment was added successfully!'), { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
            console.error(err);
        }
    };

    const handleUpdateOfferImportanceUrgency = async (key, value) => {
        try {
            setIsOperating(true);

            await API.patch('offer_importance_urgency', undefined, {
                params: { id: offerContext.offerId, [key]: value },
            });

            editOfferContext('offer', { ...offerContext.offer, [key]: value });

            enqueueSnackbar(t(offerImportanceUrgencyMessages[key]), { variant: 'success' });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsOperating(false);
        }
    };

    useEffect(() => {
        if (!canView) history.push('/');

        (async () => {
            try {
                const res = await getOffer(id);

                editOfferContext('offer', res.followUpOffers.at(-1) ?? res);
                editOfferContext('offerId', res.followUpOffers.at(-1)?.id ?? id);
                setOfferRevisions([res, ...res.followUpOffers]);
                setSelectedOffer(res.followUpOffers.length);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    if (loading) return <Loading style={{ height: '100svh' }} />;

    return (
        <>
            <Helmet>
                <title>{t('Offer view')}</title>
            </Helmet>
            <Header pageTitle={t('Offer view')} />
            <div className="page-container">
                <div className="relative flex gap-8 xl:flex-col xl:gap-16">
                    <div className="flex w-full flex-col gap-16 xl:order-2">
                        <div className="flex w-full flex-col gap-8 self-start rounded-md bg-layout-transparent p-4">
                            <h2 className="text-center">{t('Offer revisions')}</h2>
                            <div
                                className="grid justify-center gap-4"
                                style={{
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(175px, max-content))',
                                }}
                            >
                                {offerRevisions.map((offer, index) => (
                                    <div
                                        key={offer.id}
                                        className={`flex cursor-pointer flex-col items-center gap-2 rounded-md p-2 ${
                                            isDisabled
                                                ? 'pointer-events-none bg-disabled'
                                                : index === selectedOffer
                                                  ? 'pointer-events-none bg-primary-main'
                                                  : 'bg-layout-transparent-dark hover:bg-layout-transparent'
                                        }`}
                                        onClick={() => handleChangeRevision(index)}
                                    >
                                        <p className="font-bold">{`${
                                            index === 0 ? t('Original offer') : `${t('Revision number')} ${index}`
                                        }`}</p>
                                        <p>
                                            {t('Created at')}:{' '}
                                            <span className="font-bold">{formatDate(offer.createAt)}</span>
                                        </p>
                                    </div>
                                ))}
                                {offerContext.offer.offerHistory.at(-1).status !== 'LOST' &&
                                    offerContext.offer.offerHistory.at(-1).status !== 'NOT_ACCEPTED' &&
                                    offerContext.offer.offerHistory.at(-1).status !== 'ACCEPTED' &&
                                    offerContext.offer.offerHistory.at(-1).status !== 'BLOCKED' && (
                                        <div
                                            key={'new-revision'}
                                            className={`flex cursor-pointer items-center gap-2 rounded-md p-2 ${
                                                isDisabled
                                                    ? 'pointer-events-none bg-disabled'
                                                    : 'bg-secondary-main hover:bg-secondary-light'
                                            }`}
                                            onClick={handleCreateNewRevision}
                                        >
                                            <AddIcon fontSize="large" />
                                            <p className="font-bold">
                                                {t('Update the offer by creating a new revision')}
                                            </p>
                                        </div>
                                    )}
                            </div>
                        </div>

                        <div className="flex flex-col gap-8">
                            <h2>{t('Offer information')}</h2>
                            <div className="inline-flex">
                                <Tabs
                                    activeTab={offerContext.step}
                                    setActiveTab={(i) => editOfferContext('step', i)}
                                    tabs={[
                                        t('Offer details'),
                                        t('Offer articles'),
                                        t('Offer SIP'),
                                        t('Offer PDF'),
                                        t('Offer tags'),
                                    ]}
                                    disabled={isDisabled}
                                />
                            </div>
                            {isChanging ? (
                                <Loading />
                            ) : (
                                <OfferContext.Provider
                                    value={{
                                        offerContext,
                                        editOfferContext,
                                    }}
                                >
                                    {offerContext.step === 0 && (
                                        <OfferDetails key={`DETAILS_${offerContext.offerId}`} viewOnly={true} />
                                    )}
                                    {offerContext.step === 1 && (
                                        <OfferArticles key={`ARTICLES_${offerContext.offerId}`} viewOnly={true} />
                                    )}
                                    {offerContext.step === 2 && (
                                        <OfferSIP key={`SIP_${offerContext.offerId}`} viewOnly={true} />
                                    )}
                                    {offerContext.step === 3 && (
                                        <OfferPDF key={`PDF_${offerContext.offerId}`} viewOnly={true} />
                                    )}
                                    {offerContext.step === 4 && (
                                        <OfferTags key={`TAGS_${offerContext.offerId}`} viewOnly={true} />
                                    )}
                                </OfferContext.Provider>
                            )}
                        </div>

                        <div className="flex max-w-4xl flex-col gap-8 rounded-md bg-layout-transparent p-4">
                            <h2>{t('Offer comments')}</h2>
                            {canAll && (
                                <AddComment
                                    onSubmit={(newComment) => submitComment(newComment)}
                                    fileIconView={false}
                                    onOffer={true}
                                    pageLayout={true}
                                    disabled={
                                        selectedOffer !== offerRevisions.length - 1 ||
                                        offerContext.offer.offerHistory.at(-1).status === 'BLOCKED'
                                    }
                                />
                            )}
                            {offerContext.offer.OfferComments.length > 0 ? (
                                <div className="relative">
                                    {offerContext.offer.OfferComments.map((comment, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                zIndex: offerContext.offer.OfferComments.length - index + 1,
                                                position: 'relative',
                                            }}
                                        >
                                            <CommentOffer
                                                pageLayout={true}
                                                comment={comment}
                                                disabled={
                                                    selectedOffer !== offerRevisions.length - 1 ||
                                                    offerContext.offer.offerHistory.at(-1).status === 'BLOCKED'
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="flex items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                    <p className="text-lg font-bold text-warning">
                                        {t('The offer has no comments yet!')}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    {canAll && (
                        <div className="sticky top-16 flex flex-col gap-8 self-start rounded-md bg-layout-transparent p-4 xl:relative xl:top-0 xl:order-1 xl:w-full">
                            <h2 className="text-center">{t('Offer actions')}</h2>
                            {selectedOffer === offerRevisions.length - 1 && (
                                <div className="flex flex-col gap-4">
                                    {offerContext.offer.offerHistory.at(-1).status !== 'LOST' &&
                                        offerContext.offer.offerHistory.at(-1).status !== 'NOT_ACCEPTED' &&
                                        offerContext.offer.offerHistory.at(-1).status !== 'BLOCKED' && (
                                            <div className="flex flex-col gap-4 border-b border-solid border-layout-transparent pb-4 xl:flex-row sm:flex-col">
                                                <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2 xl:ml-auto sm:ml-0">
                                                    <Toggle
                                                        disabled={isDisabled}
                                                        checked={offerContext.offer.important}
                                                        setChecked={() =>
                                                            handleUpdateOfferImportanceUrgency(
                                                                'important',
                                                                !offerContext.offer.important,
                                                            )
                                                        }
                                                    />
                                                    <p>{t('Important')}</p>
                                                </div>
                                                <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2">
                                                    <Toggle
                                                        disabled={isDisabled}
                                                        checked={offerContext.offer.urgent}
                                                        setChecked={() =>
                                                            handleUpdateOfferImportanceUrgency(
                                                                'urgent',
                                                                !offerContext.offer.urgent,
                                                            )
                                                        }
                                                    />
                                                    <p>{t('Urgent')}</p>
                                                </div>
                                            </div>
                                        )}

                                    <div className="flex flex-col gap-4 xl:flex-row xl:flex-wrap xl:items-end sm:flex-col sm:flex-nowrap sm:items-stretch">
                                        {canAllContract &&
                                            offerContext.offer.offerHistory.at(-1).status === 'NEW' &&
                                            offerContext.offer.status !== 'DRAFT' && (
                                                <BasicTooltip
                                                    tip={t(
                                                        "You can't accept this offer because it contains an article on another offer which has the priority",
                                                    )}
                                                    disabled={offerContext.offer.offerArticles.every(
                                                        (a) => a.articleStatus !== 'WAITING',
                                                    )}
                                                >
                                                    <Button
                                                        className={`${styles.sent} w-full`}
                                                        startIcon={<EditIcon />}
                                                        disabled={
                                                            isDisabled ||
                                                            !!offerContext.offer.offerArticles.find(
                                                                (a) => a.articleStatus === 'WAITING',
                                                            )
                                                        }
                                                        onClick={handleAcceptOffer}
                                                    >
                                                        {t('Accept the offer and create the contract')}
                                                    </Button>
                                                </BasicTooltip>
                                            )}

                                        {canAllContract &&
                                            offerContext.offer.offerHistory.at(-1).status === 'ACCEPTED' && (
                                                <Button
                                                    disabled={isDisabled}
                                                    className={styles.accepted}
                                                    startIcon={<EditIcon />}
                                                    onClick={() => {
                                                        if (offerContext.offer.contracts.length > 0) {
                                                            history.push(
                                                                crm.base +
                                                                    crm.contracts.base +
                                                                    crm.contracts.update +
                                                                    '/' +
                                                                    offerContext.offer.contracts[0].id,
                                                            );
                                                        } else {
                                                            history.push(
                                                                crm.base +
                                                                    crm.contracts.base +
                                                                    crm.contracts.create +
                                                                    `?offerId=${offerContext.offerId}`,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {(() => {
                                                        let string;
                                                        if (offerContext.offer.contracts.length > 0) {
                                                            string = t('See contract');
                                                        } else {
                                                            string = t('Create the contract');
                                                        }
                                                        return string;
                                                    })()}
                                                </Button>
                                            )}

                                        {offerContext.offer.offerHistory.at(-1).status === 'NEW' &&
                                            offerContext.offer.status !== 'DRAFT' && (
                                                <Button
                                                    disabled={isDisabled}
                                                    startIcon={<CancelIcon />}
                                                    className={styles.lost}
                                                    onClick={handleDeclineOffer}
                                                >
                                                    {t('Refuse the offer')}
                                                </Button>
                                            )}

                                        {offerContext.offer.offerHistory.at(-1).status !== 'LOST' &&
                                            offerContext.offer.offerHistory.at(-1).status !== 'NOT_ACCEPTED' && (
                                                <Button
                                                    disabled={isDisabled}
                                                    className={styles.blocked}
                                                    startIcon={
                                                        offerContext.offer.offerHistory.at(-1).status !== 'BLOCKED' ? (
                                                            <LockIcon />
                                                        ) : (
                                                            <LockOpenIcon />
                                                        )
                                                    }
                                                    onClick={handleChangeBlockStatus}
                                                >
                                                    {offerContext.offer.offerHistory.at(-1).status !== 'BLOCKED'
                                                        ? t('Block the offer')
                                                        : t('Unblock the offer')}
                                                </Button>
                                            )}

                                        {(offerContext.offer.offerHistory.at(-1).status === 'NEW' ||
                                            offerContext.offer.offerHistory.at(-1).status === 'LOST' ||
                                            offerContext.offer.offerHistory.at(-1).status === 'NOT_ACCEPTED') &&
                                            offerContext.offer.status !== 'DRAFT' && (
                                                <div className="flex flex-col gap-4 rounded-md bg-layout-transparent p-2 xl:flex-row xl:items-end sm:flex-col sm:items-stretch">
                                                    {!newReasonField && (
                                                        <div style={{ zIndex: 9999 }}>
                                                            <LabelWrapper
                                                                label={t(
                                                                    'Choose the reasons for the lost of the offer',
                                                                )}
                                                            >
                                                                <MultiDropdown
                                                                    disabled={isDisabled}
                                                                    placeholder={t(
                                                                        'Choose the reasons for the lost of the offer',
                                                                    )}
                                                                    options={lostReasons}
                                                                    selectedOptions={lostOfferReasons}
                                                                    setSelectedOptions={(newId) => {
                                                                        if (newId === lostReasons.length - 1) {
                                                                            setNewReasonField(!newReasonField);
                                                                        } else {
                                                                            if (lostOfferReasons.indexOf(newId) > -1) {
                                                                                setLostOfferReasons(
                                                                                    lostOfferReasons.filter(
                                                                                        (opt) => opt !== newId,
                                                                                    ),
                                                                                );
                                                                            } else {
                                                                                setLostOfferReasons([
                                                                                    ...lostOfferReasons,
                                                                                    newId,
                                                                                ]);
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </LabelWrapper>
                                                        </div>
                                                    )}

                                                    {newReasonField && (
                                                        <div className="flex flex-col gap-2">
                                                            <TextField
                                                                disabled={isDisabled}
                                                                name="new reason"
                                                                label={t('Reason')}
                                                                placeholder={t('Reason')}
                                                                value={newReason}
                                                                onChange={(e) => setNewReason(e.target.value)}
                                                            />

                                                            <div className="flex justify-end gap-4">
                                                                <Button
                                                                    disabled={isDisabled}
                                                                    size="small"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        const newReasonList = [...lostReasons];
                                                                        const listLen = newReasonList.length;
                                                                        if (newReason == null) {
                                                                            setNewReasonField(!newReasonField);
                                                                            return;
                                                                        } else {
                                                                            newReasonList[listLen - 1] = newReason;
                                                                        }
                                                                        newReasonList.push(t('Add new reason'));
                                                                        setLostReasons(newReasonList);
                                                                        setNewReason('');
                                                                        setLostOfferReasons([
                                                                            ...lostOfferReasons,
                                                                            newReasonList.indexOf(newReason),
                                                                        ]);
                                                                        setNewReasonField(false);

                                                                        setNewReason(null);
                                                                        setNewReasonField(!newReasonField);
                                                                    }}
                                                                >
                                                                    {t('Add new reason')}
                                                                </Button>
                                                                <Button
                                                                    disabled={isDisabled}
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => setNewReasonField(false)}
                                                                >
                                                                    {t('Cancel')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {offerContext.offer.offerHistory.at(-1).status === 'NEW' &&
                                                        offerContext.offer.status !== 'DRAFT' && (
                                                            <Button
                                                                disabled={
                                                                    isDisabled ||
                                                                    lostOfferReasons.length === 0 ||
                                                                    newReasonField
                                                                }
                                                                startIcon={<ThumbDownAltIcon />}
                                                                className={styles.lost}
                                                                onClick={handleLoseOffer}
                                                            >
                                                                {t('Mark the offer as lost')}
                                                            </Button>
                                                        )}

                                                    {(offerContext.offer.offerHistory.at(-1).status === 'LOST' ||
                                                        offerContext.offer.offerHistory.at(-1).status ===
                                                            'NOT_ACCEPTED') && (
                                                        <Button
                                                            disabled={
                                                                isDisabled ||
                                                                lostOfferReasons.length === 0 ||
                                                                newReasonField
                                                            }
                                                            className={styles.lost}
                                                            startIcon={<AutorenewIcon />}
                                                            onClick={handleUpdateReasons}
                                                        >
                                                            {t('Update the reasons')}
                                                        </Button>
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            )}

                            {selectedOffer !== offerRevisions.length - 1 &&
                                (offerContext.offer.offerHistory.at(-1).status !== 'LOST' &&
                                offerContext.offer.offerHistory.at(-1).status !== 'NOT_ACCEPTED' &&
                                offerContext.offer.offerHistory.at(-1).status !== 'ACCEPTED' ? (
                                    <div>
                                        <SuccessButton
                                            disabled={isDisabled}
                                            startIcon={<LowPriorityIcon />}
                                            onClick={handleMarkRevisionAsHead}
                                        >
                                            {t('Mark this revision as being the last')}
                                        </SuccessButton>
                                    </div>
                                ) : (
                                    <p className="text-lg font-bold text-warning">
                                        {t('There are no actions that can be done on this revision!')}
                                    </p>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default OfferView;
