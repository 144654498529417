import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { Button, TextField } from '@material-ui/core';

import Loading from 'components/shared/loading';
import OfferContext from 'contexts/OfferContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper, Toggle } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import API from 'utils/axios';
import { errorHandling } from 'utils/index';
import * as yup from 'yup';

const OfferDetails = ({ viewOnly }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const { offerContext, editOfferContext } = useContext(OfferContext);

    const [date, setDate] = useState(new Date());
    const [expiration, setExpiration] = useState(new Date());

    /**
     * States for the client dropdown
     */
    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [offerName, setOfferName] = useState('');
    const [offerDetails, setOfferDetails] = useState('');

    const [important, setImportant] = useState(false);
    const [urgent, setUrgent] = useState(false);

    /*
        Fetch Clients and set the dropdown with clients
    */
    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/contacts', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: 'ALL',
                    },
                });
                const fetchedClients = response.data.data.contacts;
                setClients(fetchedClients);
                setClientOptions(
                    fetchedClients.map((c) => {
                        const data = JSON.parse(c.data);
                        return 'standard' in data && 'name' in data['standard']
                            ? data['standard'].name
                            : 'Nume inexistent';
                    }),
                );

                if (offerContext.offer) {
                    setOfferName(offerContext.offer.name);
                    setOfferDetails(offerContext.offer.description);
                    setDate(offerContext.offer.offerDate);
                    setExpiration(offerContext.offer.expirationDate);
                    setSelectedClient(fetchedClients.findIndex((client) => client.id === offerContext.offer.contactId));
                    setImportant(offerContext.offer.important);
                    setUrgent(offerContext.offer.urgent);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    /**
     * This function delete all info from step 1 and redirect user for offers history
     */
    const cancelOffer = () => {
        history.push(quotes.base + quotes.biddingHistory.base + quotes.biddingHistory.read);
    };

    const schema = yup.object().shape({
        expiration: yup
            .date()
            .typeError(t('The expiration date of the offer is mandatory!'))
            .required(t('The expiration date of the offer is mandatory!')),
        selectedClient: yup
            .number()
            .typeError(t('Choosing a customer for this offer is a must!'))
            .required(t('Choosing a customer for this offer is a must!')),
        offerName: yup
            .string()
            .trim()
            .typeError(t('The name of the offer is mandatory!'))
            .required(t('The name of the offer is mandatory!')),
        offerDetails: yup
            .string()
            .trim()
            .typeError(t('The description of the offer is mandatory!'))
            .required(t('The description of the offer is mandatory!')),
    });

    const createOfferDetailsOrUpdate = async () => {
        try {
            /**
             * we verify the expiration date,to not be a past date
             */
            if (expiration == null) {
                enqueueSnackbar(t('Pick a dat of expiration for the offer!'), {
                    variant: 'error',
                });
                return;
            } else if (new Date(expiration).getTime() <= new Date(date).getTime()) {
                enqueueSnackbar(
                    t('The expiration date can not be the same as date of creation or a date from the past!'),
                    {
                        variant: 'error',
                    },
                );
                return;
            }

            await schema.validate({
                expiration,
                selectedClient,
                offerName,
                offerDetails,
            });

            /**
             * If we have a offer in our context, this means that we must to update the offer
             */
            if (offerContext.offerId) {
                /**
                 * For the update of the offer
                 */
                try {
                    setLoading(true);
                    const res = await API.post(`/offer_completion/${offerContext.offerId}/OFFER`, {
                        id: offerContext.offerId,
                        data: {
                            offerDate: date,
                            expirationDate: expiration,
                            description: offerDetails,
                            name: offerName,
                            contactId: clients[selectedClient]?.id,
                            important,
                            urgent,
                        },
                    });

                    enqueueSnackbar(t('The offer first step is successfully updated!'), {
                        variant: 'success',
                    });
                    setLoading(false);

                    editOfferContext('offer', res.data.offer);
                    editOfferContext('step', 1);
                } catch (err) {
                    console.error(err);
                    enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                        variant: 'error',
                    });
                }
            } else {
                /**
                 * For the creation of the offer
                 */
                try {
                    setLoading(true);
                    const res = await API.post('/offers', {
                        offerDate: date,
                        expirationDate: expiration,
                        description: offerDetails,
                        name: offerName,
                        contactId: clients[selectedClient]?.id,
                        important,
                        urgent,
                    });

                    await API.post('/offer_status', {
                        id: res.data.offer.id,
                        status: 'DRAFT',
                    });

                    enqueueSnackbar(t('The offer first step is successfully accomplished!'), {
                        variant: 'success',
                    });
                    setLoading(false);

                    /**
                     * We set the offer id from request response
                     */

                    editOfferContext('offerId', res.data.offer.id);
                    editOfferContext('offer', res.data.offer);
                    editOfferContext('step', 1);

                    const search = new URLSearchParams();
                    search.set('id', res.data.offer.id);

                    history.push({
                        pathname: quotes.base + quotes.newBidding.base + quotes.newBidding.read,
                        search: search.toString(),
                    });
                } catch (err) {
                    console.error(err);
                    enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                        variant: 'error',
                    });
                }
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return loading ? (
        <Loading />
    ) : (
        <div className="flex max-w-3xl flex-col items-start">
            {/* Offer date picker and expiration date picker  */}
            <div className="mb-5 flex w-full gap-4 sm:flex-col sm:gap-8">
                <LabelWrapper label={t('Pick date')}>
                    <div>
                        <DatePicker date={date} setDate={setDate} disabled={viewOnly} />
                    </div>
                </LabelWrapper>

                <LabelWrapper label={t('Pick the expiration date for the offer')}>
                    <div>
                        <DatePicker date={expiration} setDate={setExpiration} disabled={viewOnly} />
                    </div>
                </LabelWrapper>
            </div>

            {/* Dropdown for the client of the offer */}
            <div className="mb-10 w-full">
                <LabelWrapper label={t('Customer')}>
                    <div>
                        <Dropdown
                            disabled={viewOnly}
                            options={clientOptions}
                            selectedOption={selectedClient}
                            setSelectedOption={(newClientIndex) => setSelectedClient(newClientIndex)}
                            label="Client"
                            placeholder={t('Choose customer (who will receive the offer)')}
                        />
                    </div>
                </LabelWrapper>
            </div>

            {/* Offer name and description */}
            <div className="mb-2 w-full">
                <TextField
                    disabled={viewOnly}
                    name="offer name"
                    placeholder={t('Offer name')}
                    value={offerName}
                    onChange={(e) => setOfferName(e.target.value)}
                />
            </div>
            <div className="w-full">
                <TextField
                    disabled={viewOnly}
                    name="offer description"
                    placeholder={t('Offer details(this will be visible for the customer)')}
                    value={offerDetails}
                    onChange={(e) => setOfferDetails(e.target.value)}
                    multiline
                    rows={4}
                />
            </div>

            {!viewOnly && (
                <div className="mt-10 flex flex-col gap-5">
                    <p>{t('Choose if the offer should be marked as important and/or urgent')}</p>
                    <div className="flex gap-5">
                        <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2">
                            <Toggle checked={important} setChecked={setImportant} />
                            <p>{t('Important')}</p>
                        </div>
                        <div className="flex items-center gap-2 rounded-md bg-layout-transparent p-2">
                            <Toggle checked={urgent} setChecked={setUrgent} />
                            <p>{t('Urgent')}</p>
                        </div>
                    </div>
                </div>
            )}

            {!viewOnly && (
                <div className="mt-20 flex flex-col gap-6">
                    <Button
                        disabled={loading}
                        color="primary"
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={createOfferDetailsOrUpdate}
                    >
                        {t('Step')} 2
                    </Button>

                    <Button disabled={loading} startIcon={<HighlightOffIcon />} onClick={() => cancelOffer()}>
                        {t('Cancel')}
                    </Button>
                </div>
            )}
        </div>
    );
};

OfferDetails.propTypes = {
    viewOnly: PropTypes.bool,
};

OfferDetails.defaultProps = {
    viewOnly: false,
};

export default OfferDetails;
