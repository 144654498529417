import React from 'react';

import PropTypes from 'prop-types';

const PipelineItemRow = ({ row, className }) => {
    return (
        <div className={`flex w-full items-center justify-between gap-2.5 ${className}`}>
            <p className="break-words text-xl leading-6 opacity-60">{row.label}:</p>
            <p className="break-all text-xl leading-6">{row.content}</p>
        </div>
    );
};

PipelineItemRow.propTypes = {
    row: PropTypes.object,
    className: PropTypes.string,
};

PipelineItemRow.defaultProps = {
    row: {},
    className: '',
};

export default PipelineItemRow;
