import { defaultTheme, rgbToHex } from 'utils';

import { getImagesToCanvas, handleElementExport, handleMultiPageExport } from './exportUtils';

const exportImagesToPDF = async (
    pageOrientation = 'landscape',
    elements,
    tenant,
    additionalElements = [],
    firstCallback = null,
    secondCallback = null,
    download = true,
    splitImage = 'multi-page',
    alias = null,
    customFileName = null,
) => {
    try {
        let dataImages = [];
        let additionalExtractedData = null;
        // ? We extract the images into a variables and we skip the auto scale in order to maintain the aspect ratio of the images

        const extractedCanvases = await getImagesToCanvas([...elements, ...additionalElements]);

        const extractedData = extractedCanvases.slice(
            0,
            elements.reduce((acc, curr) => (acc += curr.ids.length), 0),
        );
        if (additionalElements.length)
            additionalExtractedData = extractedCanvases.slice(
                elements.reduce((acc, curr) => (acc += curr.ids.length), 0),
            );

        // ? In the case of a multi page export we split the big image in smaller images in order to fit them in different PDF pages
        if (splitImage === 'multi-page') {
            for (let i = 0; i < extractedData.length; i++) {
                const extractedImages = handleMultiPageExport(
                    extractedData[i].canvas,
                    extractedData[i].disabledCropHeightsIntervals,
                    pageOrientation,
                );
                dataImages = [...dataImages, ...extractedImages];
                // ? If we have an additional element we added to the images array
                if (additionalExtractedData[i]) {
                    const extractedImage = handleElementExport(additionalExtractedData[i].canvas, pageOrientation);
                    dataImages.push(extractedImage);
                }
            }
        }

        // ? In the case of a single page export we get the image using the same function as for the the additional element
        if (splitImage === 'single-page') {
            const extractedImage = handleElementExport(extractedData[0].canvas, pageOrientation);
            dataImages = [extractedImage];
        }

        // ? We must delete the pageBreak property on the last item of the array in order to avoid creating an empty page in the PDF
        delete dataImages.at(-1).pageBreak;

        // ? Header object with the company details
        const headerDetails = {
            name: tenant.companyName,
            registerNo: tenant.companyRegisterNo,
            cui: tenant.cui,
            address: tenant.addressString,
            phone: tenant.phoneNo,
            email: tenant.email,
        };

        const colorsDetails = {};

        // ? If the company has at least one theme we will use that theme in order to get the PDF colors
        if (tenant.ThemeConfigurator.length > 0) {
            const tenantTheme = JSON.parse(
                tenant.ThemeConfigurator.find((theme) => theme.id === tenant.activeThemeId).theme,
            );
            colorsDetails.mainText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
            colorsDetails.linkText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' '));
            colorsDetails.footerText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' '));
            colorsDetails.footerBackground = rgbToHex(
                ...tenantTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' '),
            );
        }
        // ? If the company doesn't have a theme yet we use the general default M2M theme
        else {
            const parsedDefaultTheme = JSON.parse(defaultTheme.theme);
            colorsDetails.mainText = rgbToHex(...parsedDefaultTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
            colorsDetails.linkText = rgbToHex(...parsedDefaultTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' '));
            colorsDetails.footerText = rgbToHex(
                ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' '),
            );
            colorsDetails.footerBackground = rgbToHex(
                ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' '),
            );
        }

        // ? At the end we return a promise that we will be used to create the web worker where the PDF file will be created
        return new Promise((resolve, reject) => {
            // ? We create the web worker and we assign it a name and a type of 'module' witch is mandatory
            const worker = new Worker('../workers/exportImagesAsPDF.worker', {
                name: 'exportImagesAsPDF',
                type: 'module',
            });
            // ? We send a message to the worker with all the data received above
            worker.postMessage({
                dataImages,
                alias,
                headerDetails,
                colorsDetails,
                pageOrientation,
                companyLogo: tenant.logoUrl,
            });
            // ? If the action is completed successfully we receive the message witch contains the PDF
            worker.onmessage = (e) => {
                if (download) {
                    const link = document.createElement('a');
                    link.href = e.data.pdf;
                    link.setAttribute(
                        'download',
                        customFileName ? `${customFileName}.pdf` : `${new Date().toISOString().slice(0, 10)}.pdf`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
                // ? At the end we call the callbacks, close the worker and resolve the promise
                firstCallback?.();
                secondCallback?.();
                worker.terminate();
                resolve(e.data.pdf);
            };
            // ? If the actions result in a error we call the callbacks, close the worker and reject the promise
            worker.onerror = (e) => {
                console.error(e);
                firstCallback?.();
                secondCallback?.();
                worker.terminate();
                reject(e);
            };
        });
    } catch (error) {
        console.error(error);
    }
};

export default exportImagesToPDF;
