/**
 * Compact routes
 */
const resourceRoutes = {
    internalResources: {
        base: '/internal-resources',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:resourceId',
    },

    externalResources: {
        base: '/external-resources',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:resourceId',
    },
};

export const login = '/login';

export const recoverPassword = '/recover-password';

export const verifyPassword = '/reset-password/:token';

export const notFound = '/404';

export const initializeCompany = '/initialize-company';

export const affiliate = {
    base: '/affiliate-project-management',

    projectManagement: {
        projects: '/pm-projects',
        overview: '/pm-overview',
        pipeline: '/pm-pipeline',
        project: '/project-in-pm',
        projectFull: '/project-in-pm/:projectId',
    },

    projectWikis: {
        base: '/wikis',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectTasks: {
        base: '/tasks',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    planingOverviewOnProject: {
        base: '/planing-overview',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
};

export const crm = {
    base: '/crm',

    contactActivity: {
        base: '/contact-activity',
        full: '/contact-activity/:id',
    },

    contactActivityPipeline: {
        base: '/contact-activity-pipeline',
        full: '/contact-activity-pipeline/:id',
    },

    sales: {
        base: '/sales',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    contacts: {
        base: '/contacts',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    estate: {
        base: '/estate',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    contracts: {
        base: '/contracts',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:id',
        pipeline: '/pipeline',
    },

    reports: {
        base: '/reports',
    },
};

export const internalActivity = {
    base: '/internal-activity',

    timeline: {
        base: '/timeline',
        read: '/',
    },
    tasksPipeline: {
        base: '/tasks-pipeline',
        read: '/',
    },
    pipeline: {
        base: '/pipeline',
        read: '/',
    },
    partners: {
        base: '/partners',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:id',
    },
    planning: {
        base: '/planning-overview',
        create: '/create',
        read: '/',
        update: '/edit',
        updateFull: '/edit/:id',
        resource: '/resource',
        resourceFull: '/resource/:employeeId/:projectId/:teamId',
    },

    projectStructure: {
        base: '/project-structure',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
        gantt: '/gantt',
        ganttFull: '/gantt/:id',
    },

    ...resourceRoutes,
};

export const quotes = {
    base: '/quotes',

    nomenclature: {
        base: '/nomenclature',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    stocks: {
        base: '/stocks',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    categories: {
        base: '/categories',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    variations: {
        base: '/variations',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:variationId',
    },

    attributes: {
        base: '/attributes',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:attributeId',
    },

    newBidding: {
        base: '/new-bidding',
        read: '/',
    },

    viewBidding: {
        base: '/view-bidding',
        full: '/view-bidding/:id',
    },

    biddingHistory: {
        base: '/bidding-history',
        read: '/',
    },

    biddingPipeline: {
        base: '/bidding-pipeline',
        read: '/',
    },
};

export const configurator = {
    base: '/configurator',

    zones: {
        base: '/zones',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projects: {
        base: '/projects',
        create: '/create',
        createWithZone: '/create/:id',
    },

    users: {
        base: '/users',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    departments: {
        base: '/departments',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    milestones: {
        base: '/milestones',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    offerTags: {
        base: '/offer-tags',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:offerTagId',
    },

    pdfTemplates: {
        base: '/pdf-templates',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:templateId',
    },

    emailTemplates: {
        base: '/email-templates',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:emailTemplateId',
    },

    documentsTemplates: {
        base: '/documents-templates',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
};

export const playground = {
    base: '/playground',
    read: '/',
    create: '/create',
    update: '/edit',
    updateFull: '/edit/:id',
};

export const PL = {
    base: '/p_l',
    read: '/',
    create: '/create',
    update: '/edit',
    updateFull: '/edit/:id',
};

export const accountancy = {
    base: '/accountancy',

    expenseNomenclature: {
        base: '/expense-nomenclature',
        read: '/',
        create: '/create',
        update: '/edit',
    },

    revenueNomenclature: {
        base: '/revenue-nomenclature',
        read: '/',
        create: '/create',
        update: '/edit',
    },

    accountingAccounts: {
        base: '/accounting-accounts',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    contractTypeNomenclature: {
        base: '/contract-type',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:contractTypeId',
    },

    invoice: {
        base: '/invoice',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:invoiceId',
    },
    PL: {
        base: '/p_l',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
    cashflow: {
        base: '/cashflow',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
};

export const modules = {
    base: '/modules',

    worksUndertaking: {
        base: '/works-undertaking',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    salesModule: {
        base: '/sales-module',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    bookingModule: {
        base: '/booking-module',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    facilityManagement: {
        base: '/facility-management',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
};

export const projectManagement = {
    base: '/project-management',

    projectEntity: {
        base: '/project-entity',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectsPlaningOverview: {
        base: '/projects-planing-overview',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
        resource: '/resource',
        resourceFull: '/resource/:employeeId/:projectId/:teamId',
    },

    projectsPipeline: {
        base: '/projects-pipeline',
        read: '/',
    },

    projectsReports: {
        base: '/projects-reports',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectsPL: {
        base: '/projects_pl',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
    projectsCashflow: {
        base: '/projects_cashflow',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectManagementConfigurator: {
        base: '/project-management-configurator',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
};

export const projectInManagement = {
    base: '/projects-in-management',

    configurator: '/configurator',

    wiki: {
        base: '/wiki-projects',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectsTaskManagement: {
        base: '/projects-task-management',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectsTasksPipeline: {
        base: '/projects-tasks-pipeline',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    planingOverviewOnProject: {
        base: '/planing-overview-project',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    timeManagement: {
        base: '/time-management',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    filesAndDocumentsManagement: {
        base: '/files-documents-management',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectBudgets: {
        base: '/project-budgets',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    riskManagement: {
        base: '/risk-management',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectManagementReports: {
        base: '/project-management-reports',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectSettings: {
        base: '/project-settings',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectPL: {
        base: '/project_pl',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },
    projectCashflow: {
        base: '/project_cashflow',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectContracts: {
        base: '/project_contracts',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
        pipeline: '/pipeline',
    },

    projectClients: {
        base: '/project_clients',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    projectArticles: {
        base: '/project_articles',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    riem: {
        base: '/riem',
        read: '/',
        create: '/create',
        update: '/edit',
        updateFull: '/edit/:id',
    },

    ...resourceRoutes,
};

export const businessIntelligence = {
    base: '/business-intelligence',
};

export const versions = {
    base: '/versions',
};

export const puppeteer = {
    base: '/puppeteer-render-components-api',
};

export const resourceModule = {
    base: '/resource-module',

    resources: {
        base: '/resource-module/resources',
    },

    humanResources: {
        base: '/resource-module/resources/human',
        add: '/resource-module/resources/human/add',
        edit: '/resource-module/resources/human/edit',
        editFull: '/resource-module/resources/human/edit/:id',
        categories: '/resource-module/resources/human/categories',
    },

    materialResources: {
        base: '/resource-module/resources/material',
        add: '/resource-module/resources/material/add',
        edit: '/resource-module/resources/material/edit',
        editFull: '/resource-module/resources/material/edit/:id',
        categories: '/resource-module/resources/material/categories',
    },

    allocationResources: {
        base: '/resource-module/resources/allocation',
        all: '/resource-module/resources/allocation/all',
        add: '/resource-module/resources/allocation/add',
        edit: '/resource-module/resources/allocation/edit',
        editFull: '/resource-module/resources/allocation/edit/:id',
    },

    warehouse: {
        base: '/resource-module/warehouse',
        add: '/resource-module/warehouse/add',
        edit: '/resource-module/warehouse/edit',
        editFull: '/resource-module/warehouse/edit/:id',
        categories: '/resource-module/warehouse/categories',
        humanResources: '/resource-module/warehouse/human-resources',
        humanResourcesFull: '/resource-module/warehouse/human-resources/:id',
        materialResources: '/resource-module/warehouse/material-resources',
        materialResourcesFull: '/resource-module/warehouse/material-resources/:id',
    },
};
