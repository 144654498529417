import React from 'react';

import PropTypes from 'prop-types';

const PipelineItemHeader = ({ itemProps, item }) => {
    const { header } = itemProps;

    return <p className="text-xl font-medium leading-5">{header.render(item)}</p>;
};

PipelineItemHeader.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
};

PipelineItemHeader.defaultProps = {
    itemProps: {},
    item: {},
};

export default PipelineItemHeader;
