import React, { cloneElement } from 'react';

import PropTypes from 'prop-types';

const PipelineItemTitle = ({ itemProps, column, item }) => {
    const { color, backgroundColor, borderColor } = column;
    const { icon, render, status } = itemProps.title;
    const { icon: statusIcon, label } = status(item);

    return (
        <div className="flex justify-between gap-4">
            <div className="to-gradient-primary-light from-gradient-primary-dark flex h-24 w-24 flex-shrink-0 items-center justify-center rounded-xl border border-layout-transparent bg-gradient-to-tr">
                {cloneElement(icon, { className: 'w-20 h-20' })}
            </div>
            <p className="my-auto w-full break-all text-left text-2xl font-medium leading-7">{render(item)}</p>
            <div
                className="flex h-24 w-24 flex-shrink-0 flex-col items-center justify-center gap-2 rounded-xl border"
                style={{ backgroundColor, borderColor }}
            >
                {cloneElement(statusIcon, {
                    style: {
                        color,
                    },
                })}
                <p
                    className="text-center text-xl font-medium leading-5"
                    style={{
                        color,
                    }}
                >
                    {label}
                </p>
            </div>
        </div>
    );
};

PipelineItemTitle.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    column: PropTypes.object,
};

PipelineItemTitle.defaultProps = {
    itemProps: {},
    item: {},
    column: {
        key: 'DEFAULT',
        title: 'Placeholder',
        color: 'var(--main-text)',
        backgroundColor: 'var(--primary-main)',
        borderColor: 'var(--layout-transparent)',
    },
};

export default PipelineItemTitle;
