import React, { cloneElement } from 'react';

import { Box } from 'lucide-react';

import PropTypes from 'prop-types';

const PipelineTag = ({ tag }) => {
    const { icon, label, color, backgroundColor, borderColor } = tag;

    return (
        <div
            className="flex h-12 items-center gap-2 rounded-xl border pl-3 pr-4"
            style={{
                backgroundColor,
                borderColor,
            }}
        >
            {cloneElement(icon, {
                style: {
                    color,
                },
            })}
            <p className="text-lg font-medium leading-4" style={{ color }}>
                {label}
            </p>
        </div>
    );
};

PipelineTag.propTypes = {
    tag: PropTypes.object,
};

PipelineTag.defaultProps = {
    tag: {
        icon: <Box />,
        label: 'Placeholder',
        color: 'var(--main-text)',
        backgroundColor: 'var(--primary-main)',
        borderColor: 'var(--layout-transparent)',
    },
};

export default PipelineTag;
