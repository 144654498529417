import React, { Fragment, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { Button, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import FrappeGantt from 'components/frappe-gantt/frappe-gantt-layout';
import Activity from 'components/internal-activity/activity';
import ActivityModal from 'components/internal-activity/activity-modal';
import EmailCreator from 'components/internal-activity/email-creator';
import CustomModal from 'components/modals/custom_modal';
import LoadingExportModal from 'components/modals/loading-export-modal';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import ActivityContext from 'contexts/ActivityContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import useQuery from 'hooks/useQuery';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';
import exportImages from 'utils/exportImages';
import exportImagesToPDF from 'utils/exportImagesToPDF';
import { getFilteredMilestones, getTasks } from 'utils/ganttUtils';
import { getDepartments } from 'utils/getterFunctions';

import FilterMilestonesModal from './filter-milestones-modal';

const Timeline = memo(function Timeline({
    isCreating,
    setCreateActivity,
    handleToggleNotify,
    setNotifyInitialMilestone,
    fetchActivities,
    loadingActivities,
    todoActivities,
    doneActivities,
    canView,
    canAll,
    canNotify,
    canEditDocuments,
    status,
    setActivityForEdit,
    handleDisplayMilestoneGantt,
}) {
    const { t } = useTranslation();

    const { user } = useContext(UserContext);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleNotify = (milestoneId) => {
        setNotifyInitialMilestone(milestoneId);
        handleToggleNotify();
    };
    const deleteActivity = async (activity) => {
        if (user.id !== activity.authorId) return;
        if (isDeleting) return;

        setIsDeleting(true);

        try {
            await API.delete('/internal_activities', {
                data: {
                    id: activity.id,
                },
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsDeleting(false);
            fetchActivities();
        }
    };

    const updateActivity = async (activity, newValue) => {
        if (user.id !== activity.authorId) return;
        if (isUpdating) return;

        setIsUpdating(true);

        try {
            await API.post('/mark_activity', {
                id: activity.id,
                status: newValue,
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsUpdating(false);
            fetchActivities();
        }
    };

    return (
        <div
            className="mx-auto flex w-full flex-col items-center pl-24 md:pl-32 sm:pl-0"
            style={{ maxWidth: '1000px' }}
        >
            {loadingActivities && <CircularProgress />}
            {(todoActivities.length > 0 || doneActivities.length > 0) && (
                <Fragment>
                    {(((status === 'all' || status === 'todo') && todoActivities.length > 0) || isCreating) && (
                        <div className="relative w-full border-l-2 border-layout-lighter py-8 pl-8 sm:pl-4">
                            <h2 className="mb-12">{t('To do')}</h2>

                            <div
                                className={`pointer-events-none flex w-full items-center justify-center overflow-hidden rounded-md bg-layout-transparent transition-all duration-300 ${
                                    isCreating ? 'opacity-1 mb-10 h-48' : 'mb-0 h-0 opacity-0'
                                }`}
                            >
                                <CircularProgress />
                            </div>

                            {todoActivities.map((activity, aIndex) => (
                                <Activity
                                    key={activity.id}
                                    activity={activity}
                                    deleteActivity={deleteActivity}
                                    updateActivity={updateActivity}
                                    check={canAll ? true : false}
                                    actionArrow={{
                                        edit: canAll ? true : false,
                                        gantt: canView && activity.activitiesTasks.length > 0 ? true : false,
                                        notify: canNotify && activity.activitiesTasks.length > 0 ? true : false,
                                    }}
                                    onNotify={handleNotify}
                                    deleteIcon={canAll ? true : false}
                                    commentView={canAll ? true : false}
                                    milestoneCheck={canAll ? true : false}
                                    userLink={canAll ? true : false}
                                    editDocuments={canEditDocuments ? true : false}
                                    setSeeGantt={() => {
                                        handleDisplayMilestoneGantt(aIndex);
                                    }}
                                    setEditActivity={() => {
                                        setCreateActivity(true);
                                        setActivityForEdit(activity);
                                    }}
                                />
                            ))}
                        </div>
                    )}

                    {(status === 'all' || status === 'done') && doneActivities.length > 0 && (
                        <div className="relative w-full border-l-2 border-layout-light py-8 pl-8 sm:pl-4">
                            <h2 className="mb-12">{t('Done')}</h2>
                            {doneActivities.map((a) => (
                                <Activity
                                    key={a.id}
                                    activity={a}
                                    deleteActivity={deleteActivity}
                                    updateActivity={updateActivity} // To actually edit activity in the backend
                                />
                            ))}
                        </div>
                    )}
                </Fragment>
            )}

            {!todoActivities.length && !doneActivities.length && !loadingActivities && <NoDataPlaceholder />}
        </div>
    );
});

Timeline.propTypes = {
    isCreating: PropTypes.bool,
    setCreateActivity: PropTypes.func,
    handleToggleNotify: PropTypes.func,
    setNotifyInitialMilestone: PropTypes.func,
    setActivityForEdit: PropTypes.func,
    fetchActivities: PropTypes.func,
    loadingActivities: PropTypes.bool,
    todoActivities: PropTypes.array,
    doneActivities: PropTypes.array,
    canView: PropTypes.bool,
    canAll: PropTypes.bool,
    canNotify: PropTypes.bool,
    canEditDocuments: PropTypes.bool,
    status: PropTypes.string,
    handleDisplayMilestoneGantt: PropTypes.func,
};

const TimelineActivity = () => {
    const { user, checkPerm } = useContext(UserContext);
    const { tenant } = useContext(GlobalContext);
    const [query] = useQuery();
    const view = query.get('view') ?? 'Day';

    //Departments logic
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(0);

    //Component logic
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [loadingExportImage, setLoadingExportImage] = useState(false);
    const [loadingexportImagesAsPDF, setLoadingexportImagesAsPDF] = useState(false);

    const [seeGantt, setSeeGantt] = useState(false);
    const handleToggleGantt = () => setSeeGantt((prev) => !prev);

    const canView = checkPerm([
        {
            permissionId: '1',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '1',
            permissionType: 'ALL',
        },
    ]);

    const canNotify = checkPerm([
        {
            permissionId: '25',
            permissionType: 'VIEW',
        },
    ]);

    const canViewDocuments = checkPerm([
        {
            permissionId: '26',
            permissionType: 'VIEW',
        },
    ]);

    const canEditDocuments = checkPerm([
        {
            permissionId: '26',
            permissionType: 'ALL',
        },
    ]);

    //Send notification states
    const [emailUsers, setEmailUsers] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const handleToggleNotify = () => setOpenNotify((prev) => !prev);

    const [notifyInitialMilestone, setNotifyInitialMilestone] = useState(null);

    const [milestones, setMilestones] = useState([]);
    // Filter gantt milestones
    const [filteredMilestones, setFilteredMilestones] = useState([]);
    const memoizedFilteredMilestones = useMemo(() => filteredMilestones, [filteredMilestones]);
    const [ganttFilterData, setGanttFilterData] = useState({
        type: 0,
        milestones: [],
        startDate: new Date(),
        endDate: new Date(),
    });
    const [openFilterGantt, setOpenFilterGantt] = useState(false);
    const companyName = tenant.companyName;

    const handleOpenFilterGantt = () => setOpenFilterGantt((prev) => !prev);

    const handleFilterGantt = () => {
        const fMilestones = getFilteredMilestones(milestones, ganttFilterData);

        setFilteredMilestones(fMilestones);
        setOpenFilterGantt(false);
    };

    const handleDisplayMilestoneGantt = useCallback(
        (index) => {
            setGanttFilterData((prev) => ({
                ...prev,
                type: 1,
                milestones: [index],
            }));

            setFilteredMilestones([milestones[index]]);
            handleToggleGantt();
        },
        [milestones],
    );

    const customGanttDates = useMemo(
        () => ({
            startDate:
                ganttFilterData.type === 2 || ganttFilterData.type === 3 || ganttFilterData.type === 4
                    ? new Date(ganttFilterData.startDate)
                    : null,
            endDate:
                ganttFilterData.type === 2 || ganttFilterData.type === 3 || ganttFilterData.type === 4
                    ? new Date(ganttFilterData.endDate)
                    : null,
        }),
        [ganttFilterData.type, ganttFilterData.startDate, ganttFilterData.endDate],
    );

    const handleGetDepartments = async () => {
        try {
            const departments = await getDepartments();
            setDepartments(departments);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const getEmailUsers = async () => {
        try {
            const [tenantUsers, clientAccounts] = await Promise.all([
                API.get('/tenant'),
                API.get('/contacts', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                        pagesToLoad: 1,
                        type: 'ALL',
                    },
                }),
            ]);

            const formattedClients = clientAccounts.data.data.contacts.map((client) => {
                const clientFields = typeof client.data === 'string' ? JSON.parse(client.data) : client.data;
                const profile = Object.assign({}, ...Object.values(clientFields));
                client.email = profile.email;
                delete profile.email;
                delete client.data;

                return { ...client, profile };
            });

            setEmailUsers([...tenantUsers.data.data.tenantAccounts, ...formattedClients]);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    /*
        Filters
        Status: todo, done, all
        Type: comment, task, email...
    */
    const [status, setStatus] = useState('all');
    const [activityType, setActivityType] = useState('ALL');

    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    /*
        Variables for manipulating activity modal
        On create: createActivity is true
        On update: updatedActivity is the activity itself
    */
    const [isCreating, setIsCreating] = useState(false);
    const [createActivity, setCreateActivity] = useState(false);
    const [activityForEdit, setActivityForEdit] = useState(null);

    const submitNewActivity = async (reqBody) => {
        if (isCreating) return;

        try {
            setIsCreating(true);
            await API.post('/internal_activities', reqBody);
            enqueueSnackbar(t('Activity added successfully!'), { variant: 'success' });
            setCreateActivity(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    const editActivity = async (reqBody) => {
        if (isCreating) return;

        let newReqBody = {};

        for (const [key, value] of reqBody.entries()) {
            newReqBody = { ...newReqBody, [key]: JSON.parse(value) };
        }

        try {
            setIsCreating(true);
            await API.put('/internal_activities', newReqBody);
            enqueueSnackbar(t('Activity was edited successfully!'), { variant: 'success' });
            setActivityForEdit(null);
            setCreateActivity(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    /*
        Fetching data periodically, and separating all activities in 2 arrays
        Update occurs only if differences are spotted
    */
    const [loadingActivities, setLoadingActivities] = useState(true);
    const [todoActivities, setTodoActivities] = useState([]);
    const [doneActivities, setDoneActivities] = useState([]);
    const memoizedTodoActivities = useMemo(() => todoActivities, [todoActivities]);
    const memoizedDoneActivities = useMemo(() => doneActivities, [doneActivities]);

    const fetchActivities = useCallback(async () => {
        setLoadingActivities(true);

        const params = {
            currentPage: 0,
            perPage: 99999,
            pagesToLoad: 1,
        };

        const res = await API.get('/internal_activities', { params });
        let activities = res.data.activity;

        if (!canViewDocuments) {
            activities = activities.filter(
                (activity) => activity.actionType !== 'DOCUMENTS' && activity.actionType !== 'FILES',
            );
        }

        const milestoneActivities = activities.filter((activity) => activity.activitiesTasks.length > 0);
        setMilestones(milestoneActivities);

        const sortedMilestones = milestoneActivities.sort(
            (a, b) => new Date(a.createAt).getTime() - new Date(b.createAt).getTime(),
        );
        setFilteredMilestones(sortedMilestones);

        const todoFiltered = [
            ...activities.filter(
                (a) =>
                    a.status === 'TODO' &&
                    (activityType === 'ALL' || activityType === a.actionType) &&
                    (selectedDepartment === 0 || a.departmentId === departments[selectedDepartment - 1].id),
            ),
        ];

        if (JSON.stringify(todoFiltered) !== JSON.stringify(todoActivities)) {
            setTodoActivities(todoFiltered);
        }

        const doneFiltered = [
            ...activities.filter(
                (a) =>
                    a.status === 'DONE' &&
                    (activityType === 'ALL' || activityType === a.actionType) &&
                    (selectedDepartment === 0 || a.departmentId === departments[selectedDepartment - 1].id),
            ),
        ];
        if (JSON.stringify(doneFiltered) !== JSON.stringify(doneActivities)) {
            setDoneActivities(doneFiltered);
        }

        setLoadingActivities(false);
    }, [departments, canViewDocuments, selectedDepartment, activityType]);

    useEffect(() => {
        if (!canView) return;

        const getData = async () => {
            try {
                await Promise.all([handleGetDepartments(), getEmailUsers()]);
            } catch (error) {
                console.error(error);
            }
        };

        getData();
    }, [canView]);

    useEffect(() => {
        if (!departments.length) return;
        fetchActivities();
    }, [departments.length, activityType, selectedDepartment]);

    const headerText = selectedDepartment !== 0 ? departments[selectedDepartment - 1].name : t('internal-activity');

    // Export Gantt as image logic

    const handleExportGanntAsImage = async () => {
        try {
            setLoadingExportImage(true);

            const ganttQuery = [
                {
                    ids: ['gantt-wrapper-root'],
                    type: 'gantt',
                    element: {
                        type: 'milestones',
                        purpose: 'ia',
                    },
                    data: {
                        view,
                        customDates: customGanttDates,
                        selectedEntities: memoizedFilteredMilestones.map((m) => m.id),
                    },
                    request: {
                        params: { currentPage: 0, perPage: 99999, pagesToLoad: 1 },
                    },
                },
            ];
            await exportImages(ganttQuery, true);
        } catch (error) {
            enqueueSnackbar(t("Gantt couldn't be exported"), { variant: 'error' });
            console.error(error);
        } finally {
            setLoadingExportImage(false);
        }
    };

    // Export Gantt as pdf logic

    const handleExportGanntAsPDF = async () => {
        try {
            setLoadingexportImagesAsPDF(true);

            await exportImagesToPDF(
                'landscape',
                [
                    {
                        ids: ['gantt-wrapper-root'],
                        type: 'gantt',
                        element: {
                            type: 'milestones',
                            purpose: 'ia',
                        },
                        data: {
                            view,
                            customDates: customGanttDates,
                            selectedEntities: memoizedFilteredMilestones.map((m) => m.id),
                        },
                        request: {
                            params: { currentPage: 0, perPage: 99999, pagesToLoad: 1 },
                        },
                    },
                ],
                tenant,
                [
                    {
                        ids: ['gantt-legend-root'],
                        type: 'gantt-legend',
                        element: {
                            type: 'milestones',
                        },
                    },
                ],
                () => setLoadingexportImagesAsPDF(false),
                null,
                true,
                'multi-page',
                companyName,
                `Gantt_Export_${new Date().toISOString().slice(0, 10)}_${companyName}.pdf`,
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Timeline Activity')}</title>
            </Helmet>
            <Header
                pageTitle={headerText}
                action={
                    <Fragment>
                        {canAll && seeGantt !== false && (
                            <Button
                                startIcon={loadingExportImage ? <CircularProgress size={20} /> : <ImportExportIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={loadingExportImage ? undefined : handleExportGanntAsImage}
                            >
                                {t('Export Gantt as image')}
                            </Button>
                        )}
                        {canAll && seeGantt !== false && (
                            <Button
                                startIcon={
                                    loadingexportImagesAsPDF ? <CircularProgress size={20} /> : <ImportExportIcon />
                                }
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={loadingexportImagesAsPDF ? undefined : handleExportGanntAsPDF}
                            >
                                {t('Export Gantt as PDF')}
                            </Button>
                        )}
                        {canNotify && seeGantt === false && (
                            <Button
                                startIcon={<EmailIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={handleToggleNotify}
                            >
                                {t('Notify')}
                            </Button>
                        )}
                        {canAll && seeGantt === false && (
                            <div
                                className={`duration-350 transform transition-all ${
                                    createActivity ? 'translate-x-8 opacity-0' : 'translate-x-0 opacity-100'
                                }`}
                            >
                                <Button
                                    startIcon={<AddIcon />}
                                    color="secondary"
                                    style={{ borderRadius: '999px' }}
                                    onClick={() => {
                                        setCreateActivity(true);
                                    }}
                                >
                                    {t('Add activity')}
                                </Button>
                            </div>
                        )}
                    </Fragment>
                }
                toolbar={
                    <div className="flex items-center gap-8">
                        {!seeGantt ? (
                            <Tabs
                                tabs={[t('All'), ...(departments?.map((d) => d.name) ?? [])]}
                                activeTab={selectedDepartment}
                                setActiveTab={setSelectedDepartment}
                            />
                        ) : (
                            <div className="flex-shrink-0">
                                <Button
                                    style={{ borderRadius: '999px' }}
                                    startIcon={<FilterListIcon />}
                                    onClick={handleOpenFilterGantt}
                                >
                                    {t('Filter milestones')}
                                </Button>
                            </div>
                        )}
                        <div className="flex-shrink-0">
                            <Button style={{ borderRadius: '999px' }} onClick={handleToggleGantt}>
                                {seeGantt === true ? t('See Timeline') : t('See Gantt')}
                            </Button>
                        </div>
                    </div>
                }
                toolbarSecondary={
                    !seeGantt ? (
                        <div className="flex flex-col">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="status"
                                    name="status-filter"
                                    value={status}
                                    onChange={handleStatusChange}
                                >
                                    <div className="no-user-select-recursive flex flex-wrap items-center border-b border-layout-lighter">
                                        <FormControlLabel value="all" control={<Radio />} label={t('All')} />
                                        <FormControlLabel value="todo" control={<Radio />} label={t('To do')} />
                                        <FormControlLabel value="done" control={<Radio />} label={t('Done')} />
                                    </div>
                                </RadioGroup>
                            </FormControl>

                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="activity-type"
                                    name="activity-type-filter"
                                    value={activityType}
                                    onChange={handleTypeChange}
                                >
                                    <div className="no-user-select-recursive flex flex-wrap items-center">
                                        <FormControlLabel value="ALL" control={<Radio />} label={t('All')} />
                                        <FormControlLabel value="COMMENT" control={<Radio />} label={t('Comments')} />
                                        <FormControlLabel value="TASK" control={<Radio />} label={t('Tasks')} />
                                        <FormControlLabel value="FILES" control={<Radio />} label={t('Files')} />
                                        <FormControlLabel
                                            value="DOCUMENTS"
                                            control={<Radio />}
                                            label={t('Documents')}
                                        />
                                        {/* <FormControlLabel
                                        value="INVOICES_AND_BILLS"
                                        control={<Radio />}
                                        label={t('Invoices/Bills')}
                                    /> */}
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : null
                }
            />
            {canView && (
                <div className="page-container">
                    {/* Gantt for all tasks */}
                    {seeGantt && (
                        <Fragment>
                            <FrappeGantt
                                milestones={memoizedFilteredMilestones}
                                customGanttDates={customGanttDates}
                                getTasksFunction={getTasks}
                            />

                            <CustomModal open={openFilterGantt}>
                                <FilterMilestonesModal
                                    setOpen={setOpenFilterGantt}
                                    ganttFilterData={ganttFilterData}
                                    setGanttFilterData={setGanttFilterData}
                                    handleFilterGantt={handleFilterGantt}
                                    milestones={milestones}
                                />
                            </CustomModal>

                            <LoadingExportModal open={loadingExportImage || loadingexportImagesAsPDF} />
                        </Fragment>
                    )}

                    {/* Timeline */}
                    {!seeGantt && (
                        <ActivityContext.Provider
                            value={{
                                isCreating,
                                setIsCreating,
                                submitNewActivity,
                                fetchActivities,
                                isContact: false,
                                setActivityForEdit,
                                activityForEdit,
                                editActivity,
                                createActivity,
                            }}
                        >
                            <div className="relative z-40 flex items-start justify-center lg:items-center">
                                <Timeline
                                    isCreating={isCreating}
                                    setCreateActivity={setCreateActivity}
                                    setActivityForEdit={setActivityForEdit}
                                    handleToggleNotify={handleToggleNotify}
                                    setNotifyInitialMilestone={setNotifyInitialMilestone}
                                    fetchActivities={fetchActivities}
                                    loadingActivities={loadingActivities}
                                    todoActivities={memoizedTodoActivities}
                                    doneActivities={memoizedDoneActivities}
                                    canView={canView}
                                    canAll={canAll}
                                    canNotify={canNotify}
                                    canEditDocuments={canEditDocuments}
                                    status={status}
                                    handleDisplayMilestoneGantt={handleDisplayMilestoneGantt}
                                />

                                {/* New Activity Modal */}
                                {canAll && createActivity && (
                                    <div className="sticky top-8 z-50 ml-8 rounded-md bg-layout-transparent xl:fixed xl:left-2/4 xl:top-2/4 xl:ml-0 xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:bg-layout-lighter">
                                        <ActivityModal
                                            close={() => setCreateActivity(false)}
                                            selectedDepartment={selectedDepartment}
                                            activityForEdit={activityForEdit ?? null}
                                            editDocuments={canEditDocuments ? true : false}
                                        />
                                    </div>
                                )}
                                <div
                                    className={`fixed left-0 top-0  z-40 hidden h-svh w-screen ${
                                        createActivity ? 'xl:flex' : ''
                                    }`}
                                    style={{ backgroundColor: 'rgba(0,0,0,0.3)', backdropFilter: 'blur(5px)' }}
                                    onClick={() => setCreateActivity((prev) => !prev)}
                                />

                                <CustomModal open={openNotify}>
                                    <EmailCreator
                                        cancel={() => {
                                            setNotifyInitialMilestone(null);
                                            handleToggleNotify();
                                        }}
                                        initialMilestone={notifyInitialMilestone}
                                        users={emailUsers}
                                        emailReplyToUsers={emailUsers.filter((value) => value.id === user.id)}
                                        milestones={milestones}
                                        purpose="CRM"
                                        exportProps={{
                                            purpose: 'ia',
                                            params: { currentPage: 0, perPage: 99999, pagesToLoad: 1 },
                                        }}
                                    />
                                </CustomModal>
                            </div>
                        </ActivityContext.Provider>
                    )}
                </div>
            )}
        </>
    );
};

export default TimelineActivity;
