import React, { useEffect, useRef, useState } from 'react';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Popover } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import useInViewport from 'hooks/useInViewport';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Search } from '../Search/Search';

export const MultiDropdown = (props) => {
    const { t } = useTranslation();
    const { options, selectedOptions, setSelectedOptions, placeholder, variant, icon, disabled, hiddenOptions } = props;

    const [searchValue, setSearchValue] = useState('');

    const optionsRef = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const isInView = useInViewport({ current: anchorEl });

    const open = Boolean(anchorEl);
    const id = open ? 'multi-dropdown-id' : undefined;

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        let observer = null;

        if (open) {
            setTimeout(() => {
                observer = new ResizeObserver(() => {
                    if (optionsRef.current) {
                        const { width: anchorWidth } = getComputedStyle(anchorEl);
                        if (parseFloat(anchorWidth) >= 200) optionsRef.current.style.width = anchorWidth;
                    }
                });

                observer.observe(anchorEl);
            }, 0);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [open]);

    useEffect(() => {
        if (!isInView) handleClose();
    }, [isInView]);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div
                className={`relative inline-block w-full flex-shrink-0 ${
                    disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
            >
                {/* eslint-disable-next-line */}
                <div
                    className={`dropdown-selected ${variant} ${open ? 'clicked' : ''} ${
                        selectedOptions.length > 0 && 'hasValue'
                    } ${icon !== null && 'is-icon'} ${disabled ? 'disabled' : ''}`}
                    onClick={!disabled ? (open ? handleClose : handleOpen) : undefined}
                >
                    {icon !== null ? (
                        <>{icon}</>
                    ) : (
                        <>
                            <p className="pointer-events-none font-medium text-buttons-text">
                                {selectedOptions.length > 0
                                    ? `${selectedOptions.length} ${t('selected')}`
                                    : (placeholder ?? t('Choose options'))}
                            </p>
                            <KeyboardArrowDownIcon
                                className={`flex-shrink-0 transform text-buttons-text ${
                                    open ? 'rotate-180' : 'rotate-0'
                                }`}
                                style={{ transition: 'transform .2s ease' }}
                            />
                        </>
                    )}
                </div>

                {/* Options */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    marginThreshold={12}
                    className="-mt-1"
                >
                    <div ref={optionsRef} className={`dropdown-options ${variant}`}>
                        <div className="search-li w-full">
                            <Search light value={searchValue} setValue={setSearchValue} />
                        </div>
                        <ul className="z-50">
                            {options.map((option, index) => {
                                if (String(option).toLowerCase().search(searchValue.toLocaleLowerCase()) >= 0) {
                                    const isHidden =
                                        hiddenOptions.findIndex((disabledOption) => disabledOption === index) >= 0;

                                    if (isHidden) return null;

                                    return (
                                        // eslint-disable-next-line
                                        <li key={Math.random()} onClick={() => setSelectedOptions(index)}>
                                            {selectedOptions.indexOf(index) > -1 ? (
                                                <CheckBoxIcon
                                                    className="text-buttons-text"
                                                    style={{ fontSize: '1.20rem' }}
                                                />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon
                                                    className="text-buttons-text"
                                                    style={{ fontSize: '1.20rem' }}
                                                />
                                            )}
                                            <p
                                                className={`ml-2 text-sm font-medium text-buttons-text ${
                                                    Boolean(option) && option.length > 32
                                                        ? 'overflow-hidden overflow-ellipsis whitespace-nowrap'
                                                        : ''
                                                }`}
                                            >
                                                {option}
                                            </p>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    </div>
                </Popover>
            </div>
        </ClickAwayListener>
    );
};

MultiDropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    selectedOptions: PropTypes.arrayOf(PropTypes.number),
    setSelectedOptions: PropTypes.func,
    placeholder: PropTypes.string,
    icon: PropTypes.element,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    hiddenOptions: PropTypes.array,
};

MultiDropdown.defaultProps = {
    options: [],
    selectedOptions: [],
    setSelectedOptions: () => null,
    icon: null,
    variant: 'default',
    disabled: false,
    hiddenOptions: [],
};
